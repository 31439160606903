import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from 'lux/components';
import { useSnackbar } from 'notistack';

import { useEditAssignment } from 'hooks/useEditAssignment/useEditAssignment';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './CancelPlannedRemovalModal.styles';
import { CancelPlannedRemovalModalProps } from './CancelPlannedRemovalModal.types';

export const CancelPlannedRemovalModal = ({ open, onClose, assignmentData }: CancelPlannedRemovalModalProps) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading } = useEditAssignment(assignmentData.project.id, assignmentData.id);

  const handleSubmit = () => {
    mutate(
      {
        endDate: null,
      },
      {
        onSuccess: () => {
          onClose();
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['assignment.cancelPlannedRemoval.snackbar.success'] },
              {
                personName: `${assignmentData.person.firstName} ${assignmentData.person.lastName}`,
                projectName: assignmentData.project.name,
              },
            ),
          );
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{formatMessage({ id: AppMessages['assignment.cancelPlannedRemoval.title'] })}</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        {formatMessage(
          { id: AppMessages['assignment.cancelPlannedRemoval.message'] },
          {
            personName: `${assignmentData.person.firstName} ${assignmentData.person.lastName}`,
          },
        )}
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button sx={styles.cancelButton} variant="text" type="button" onClick={onClose}>
          {formatMessage({ id: AppMessages['button.back'] })}
        </Button>
        <Button loading={isLoading} onClick={handleSubmit} variant="contained">
          {formatMessage({ id: AppMessages['assignment.cancelPlannedRemoval.button.submit'] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
