import { useEffect } from 'react';

import { Divider } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { SearchbarDebounced } from 'ui/searchbarDebounced/SearchbarDebounced';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { NeedsActiveFilters } from 'shared/needs/needsFilters/needsActiveFilters/NeedsActiveFilters';

import { NeedsSortingBar } from './needsSortingBar/NeedsSortingBar';
import * as styles from './NeedsListTopbar.styles';

export const NeedsListTopbar = () => {
  const { formatMessage } = useLocale();

  const { filters, setFilterValue } = useNeedsFilters();

  const handleSearch = (value: string) => {
    setFilterValue('search', value);
  };

  const handleClear = () => {
    setFilterValue('search', '');
  };

  useEffect(() => {
    setFilterValue('search', filters.search || '');
  }, [filters.search]);

  return (
    <>
      <SearchbarDebounced
        label={formatMessage({ id: AppMessages['needs.searchbar.label'] })}
        name="search"
        onChange={handleSearch}
        value={filters.search || ''}
        isClearable={!!filters.search}
        onClear={handleClear}
      />
      <Divider sx={styles.divider} />
      <NeedsActiveFilters />
      <NeedsSortingBar />
    </>
  );
};
