import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { peopleKeys } from 'utils/queryKeys';
import { getPeople } from 'api/actions/getPeople/getPeople';
import { GetPeopleParams, GetPeopleResponse } from 'api/actions/getPeople/getPeople.types';

export const usePeople = () => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...peopleKeys.peopleList];

  return useQuery<GetPeopleParams, GetPeopleResponse>(queryKey, getPeople, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    args: {
      limit: 999,
    },
  });
};
