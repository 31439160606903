import { Outlet } from 'react-router-dom';

import { TopBar } from 'ui/topBar/TopBar';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { Footer } from 'ui/footer/Footer';
import { Header } from 'ui/header/Header';
import { isProduction } from 'utils/isProduction';

export const Layout = () => {
  const { isAuthenticated } = useAuth();
  const { data } = useCurrentUser();

  return (
    <>
      {!isProduction && <Header data={data} />}
      {isAuthenticated && <TopBar user={data?.person} />}
      <Outlet />
      <Footer />
    </>
  );
};
