import { AxiosError } from 'axios';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages, Translation } from 'i18n/messages';

export const getApiError = (error: AxiosError, options?: { fallback?: string }) => {
  const { formatMessage } = intl.currentIntl;

  const code: string | null = error.response?.data.error.code || null;
  const message = error.response?.data.error.message || formatMessage({ id: AppMessages['error.unknown'] });

  if (code && Object.hasOwn(AppMessages, code)) {
    return formatMessage({ id: AppMessages[code as Translation] });
  }

  if (options?.fallback) {
    return options.fallback;
  }

  return message;
};
