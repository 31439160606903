import { Dialog } from '@mui/material';

import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { AssignmentsFiltersContextController } from 'context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';

import { PlanProjectExtensionProps } from './PlanProjectExtensionModal.types';
import { PlanProjectExtensionContent } from './planProjectExtensionContent/PlanProjectExtensionContent';

export const PlanProjectExtensionModal = ({ open, onClose, projectId, projectEndDate }: PlanProjectExtensionProps) => {
  const initialFilters = {
    status: [
      AssignmentStatus.active,
      AssignmentStatus.leaving,
      AssignmentStatus.temporary,
      AssignmentStatus.preassigned,
    ],
  };

  return (
    <AssignmentsFiltersContextController initialFilters={initialFilters}>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
        <PlanProjectExtensionContent projectId={projectId} projectEndDate={projectEndDate} onClose={onClose} />
      </Dialog>
    </AssignmentsFiltersContextController>
  );
};
