import { Box, Stack, Divider } from '@mui/material';
import { CloseIcon } from 'lux/icons';

import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { hideInPdf } from 'app/project/projectReport/ProjectReport.styles';

import { DashboardSideCardProps } from './DashboardSideCard.types';
import * as styles from './DashboardSldeCard.styles';

export const DashboardSideCard = ({ onClose, additionalHeaderButton, children, isReport }: DashboardSideCardProps) => (
  <>
    <Stack direction="row-reverse" spacing={1} padding={1} sx={hideInPdf}>
      <SquareIconButton onClick={onClose}>
        <CloseIcon />
      </SquareIconButton>
      {additionalHeaderButton}
    </Stack>
    {!isReport && <Divider />}
    <Box padding={2} sx={styles.container}>
      {children}
    </Box>
  </>
);
