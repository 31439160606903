import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from 'lux/components';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLocale } from 'hooks/useLocale/useLocale';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { useRemoveUncertainPerson } from 'hooks/useRemoveUncertainPerson/useRemoveUncertainPerson';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { Translation } from 'ui/translation/Translation';

import { ConfirmUncertainRemovalModalProps } from './ConfirmUncertainRemovalModal.types';

export const ConfirmUncertainRemovalModal = ({ open, onClose }: ConfirmUncertainRemovalModalProps) => {
  const { formatMessage } = useLocale();
  const { person } = usePersonData();

  const { mutate, isLoading } = useRemoveUncertainPerson(person.employeeId);

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || AppRoute.people;

  const handleRemove = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          enqueueSnackbar(formatMessage({ id: AppMessages['people.removeUncertain.snackbar.success'] }));
          navigate(from);
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Translation id="people.removeUncertain.title" />
      </DialogTitle>
      <DialogContent>
        <Translation id="people.removeUncertain.message" />
      </DialogContent>
      <DialogActions>
        <Button variant="outlinedGray" color="primary" size="medium" onClick={onClose} disabled={isLoading}>
          <Translation id="button.cancel" />
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="medium"
          color="error"
          loading={isLoading}
          onClick={handleRemove}
        >
          <Translation id="button.remove" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
