import { Styles } from 'lux/theme';

export const message: Styles = {
  color: 'button.primary',
  '.MuiButton-sizeMedium.MuiButton-sizeMedium': {
    padding: '0 1px 4px 0px',
    maxHeight: 'initial',
  },
  '&:hover': {
    backgroundColor: 'unset',
  },
};
