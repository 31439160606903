import { Button, Table, Tooltip, Typography } from 'lux/components';
import { Box } from '@mui/material';
import { OpenInNewIcon } from 'lux/icons';

import { Translation } from 'ui/translation/Translation';
import { InvoiceStatusBadge } from 'ui/invoiceStatusBadge/InvoiceStatusBadge';
import { getCurrentDayStartDate, getNumberOfCalendarDaysBetweenDates, parseDate } from 'utils/dateUtils';
import { useFormatCurrency } from 'hooks/useFormatCurrency/useFormatCurrency';
import { calculatePriceInDollars } from 'utils/types/priceUtils';

import * as styles from './InvoiceRow.styles';
import { InvoiceRowProps } from './InvoiceRow.types';
import { InvoiceActions } from './invoiceActions/InvoiceActions';

export const InvoiceRow = ({ invoice, isClientView }: InvoiceRowProps) => {
  const formatCurrency = useFormatCurrency(invoice.currency);
  const formatPrice = (priceInCents: number) => formatCurrency(calculatePriceInDollars(priceInCents));

  const currentDate = new Date();

  const lastDaysCount = getNumberOfCalendarDaysBetweenDates(
    getCurrentDayStartDate(),
    parseDate(invoice.statusChangeDate),
  );

  return (
    <Table.Row sx={styles.row}>
      <Table.Cell>{invoice.number}</Table.Cell>
      <Table.Cell>
        <InvoiceStatusBadge
          invoice={invoice}
          status={invoice.status}
          billingCycleEndDate={parseDate(invoice.billingCycle.end)}
          currentDate={currentDate}
          showInvoiceNumber={false}
        />
        <Box sx={styles.statusInfo}>
          <Typography variant="caption" fontWeight={500} color="text.secondary">
            <Translation
              id="projectDetails.invoices.status.days"
              values={{
                daysCount: lastDaysCount,
              }}
            />
          </Typography>
        </Box>
      </Table.Cell>
      {!isClientView && <Table.Cell>{invoice.workStatement.number}</Table.Cell>}
      <Table.Cell>
        <Box>
          <Translation id="projectDetails.invoices.billingCycle.from" values={{ date: invoice.billingCycle.start }} />
        </Box>
        {invoice.billingCycle.end && (
          <Box>
            <Translation id="projectDetails.invoices.billingCycle.to" values={{ date: invoice.billingCycle.end }} />
          </Box>
        )}
      </Table.Cell>
      <Table.Cell>{formatPrice(invoice.amount.value)}</Table.Cell>
      {isClientView && (
        <Tooltip title={invoice.projects} placement="right-start" arrow>
          <Table.Cell width={200} sx={styles.projectLabel}>
            {invoice.projects}
          </Table.Cell>
        </Tooltip>
      )}
      {isClientView && (
        <Table.Cell>
          <Box sx={styles.actionsWrapper}>
            <InvoiceActions invoice={invoice} />
          </Box>
        </Table.Cell>
      )}
      <Table.Cell width={80}>
        {invoice.previewUrl && process.env.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && (
          <Button iconOnly variant="text" color="primary" href={invoice.previewUrl} target="_blank" rel="noreferrer">
            <OpenInNewIcon />
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );
};
