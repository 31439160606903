import { Styles } from 'styles/theme';

export const content: (iconOnly: boolean) => Styles = (iconOnly) => ({
  overflow: 'hidden',
  width: iconOnly ? 0 : '124px',
  marginLeft: iconOnly ? 0 : 1.5,
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  transition: (theme) =>
    theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: iconOnly ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
    }),
});

export const title: Styles = {
  color: 'text.primary',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};
