import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { DataDisplay } from 'lux/components';

import { PersonRate } from 'api/actions/getPerson/getPerson.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { AppMessages } from 'i18n/messages';
import { Translation } from 'ui/translation/Translation';
import { addDaysToDate, isDateAfter, parseDate } from 'utils/dateUtils';
import { sortArr } from 'utils/sortArr';

import { PersonRatesHistoryModalProps } from './PersonRatesHistoryModal.types';

export const PersonRatesHistoryModal = ({ isOpen, onClose }: PersonRatesHistoryModalProps) => {
  const {
    person: { currentPersonRate, previousPersonRates, futurePersonRate },
  } = usePersonData();
  const { formatMessage } = useLocale();

  const mergedRates = [futurePersonRate, currentPersonRate, ...previousPersonRates].filter(Boolean) as PersonRate[];

  const getHeaderText = (appliesFrom: string, appliesUntil: string | null, enabled: boolean) => {
    if (!appliesUntil) {
      if (!enabled) {
        return appliesFrom;
      }

      const isFutureRate = isDateAfter(parseDate(appliesFrom), new Date());
      if (isFutureRate) {
        const from = formatMessage({ id: AppMessages['personDetails.ratesHistoryModal.dateRange.from'] });
        return `${from}: ${appliesFrom}`;
      }

      const current = formatMessage({ id: AppMessages['personDetails.ratesHistoryModal.dateRange.current'] });
      return `${appliesFrom} - ${current}`;
    }

    const isEndingInFuture = isDateAfter(addDaysToDate(parseDate(appliesUntil), 1), new Date());
    if (isEndingInFuture) {
      const current = formatMessage({ id: AppMessages['personDetails.ratesHistoryModal.dateRange.current'] });
      return `${appliesFrom} - ${appliesUntil} (${current})`;
    }

    return `${appliesFrom} - ${appliesUntil}`;
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>{formatMessage({ id: AppMessages['personDetails.ratesHistoryModal.title'] })}</DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={2}>
          {sortArr(mergedRates, (a, b) => new Date(b.appliesFrom).valueOf() - new Date(a.appliesFrom).valueOf()).map(
            ({ id, appliesFrom, appliesUntil, rate, currency, enabled }) => (
              <DataDisplay key={id} header={getHeaderText(appliesFrom, appliesUntil, enabled)}>
                {`${rate} ${currency}`}
              </DataDisplay>
            ),
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlinedGray" onClick={onClose} fullWidth>
          <Translation id="personDetails.ratesHistoryModal.backButton" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
