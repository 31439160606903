import { QueryFn } from 'hooks/useQuery/useQuery.types';

import { GetExtremeConfirmedInvoiceResponse } from './getExtremeConfirmedInvoice.types';

export const getExtremeConfirmedInvoice: (
  projectId: string,
  includeEarliest: boolean,
) => QueryFn<{}, GetExtremeConfirmedInvoiceResponse> = (projectId: string, includeEarliest: boolean) => (args) => ({
  endpoint: `invoices/${projectId}/extreme-confirmed-invoice`,
  args: {
    ...args,
    includeEarliest: includeEarliest || undefined,
  },
});
