import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './Header.styles';
import { HeaderProps } from './Header.types';

export const Header = ({ data }: HeaderProps) => {
  const { formatMessage } = useLocale();
  const environment = process.env.REACT_APP_ENVIRONMENT ?? 'undefined';
  const accessType = data?.metadata.accessType;
  const fullName = `${data?.person.firstName} ${data?.person.lastName}`;
  const message = accessType
    ? { id: AppMessages['header.environmentWithAccessType'] }
    : { id: AppMessages['header.environment'] };

  return (
    <Box sx={styles.header}>
      <Typography fontSize={14}>{formatMessage(message, { environment, accessType, fullName })}</Typography>
    </Box>
  );
};
