import { useEffect } from 'react';

import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProject } from 'hooks/useProject/useProject';
import { AppMessages } from 'i18n/messages';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { useRequestNeed } from 'hooks/useRequestNeed/useRequestNeed';
import { needSchema } from '../../schema/Need.schema';
import { NeedFormDto, NeedPayload } from '../../Need.types';
import { NeedForm } from '../../form/NeedForm';
import { Seniority } from 'api/types/Seniority.enum';
import { sortSkillsByWeight } from 'utils/sortSkillsByWeight';
import { formatDate } from 'utils/dateUtils';
import { Loader } from 'ui/loader/Loader';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { NeedType } from 'api/types/NeedType.enum';

import { RequestNeedContentProps } from './RequestNeedContentProps.types';

const defaultValues: NeedFormDto = {
  type: NeedType.assignment,
  replacedDays: '',
  role: '',
  seniorityEnabled: false,
  seniority: '',
  fte: '',
  fteCustom: '',
  additionalInformation: '',
  startDate: null,
  endDate: null,
  skillSet: [],
};

export const RequestNeedContent = ({ projectId, onClose, onChangeWidth }: RequestNeedContentProps) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } = useRequestNeed(projectId);
  const { data: projectData, isLoading: isProjectLoading } = useProject(projectId);

  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const form = useIntlForm<NeedFormDto>({
    defaultValues,
    mode: 'onSubmit',
    resolver: joiResolver(needSchema(projectData?.startDate)),
  });

  const onSubmit = async (need: NeedFormDto) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const skillSet = need.skillSet?.map(({ isDraft, ...skill }) => skill);

    const payload: NeedPayload = {
      type: need.type,
      replacedDays: need.replacedDays,
      role: need.role,
      fte: need.fte,
      startDate: need.startDate && formatDate(need.startDate),
    };

    if (need.fteCustom) {
      payload.fte = need.fteCustom;
    }

    if (need.seniority) {
      payload.seniority = need.seniority;
    } else {
      payload.seniority = Seniority.withoutLevel;
    }

    if (need.additionalInformation) {
      payload.additionalInformation = need.additionalInformation;
    }

    if (need.endDate) {
      payload.endDate = formatDate(need.endDate);
    }

    if (skillSet && skillSet.length > 0) {
      payload.skillSet = sortSkillsByWeight(skillSet);
    }

    await mutateAsync(payload, {
      onSuccess: () => {
        enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.requestNeed.success'] }));
        onClose();
      },
    });
  };

  const skillSet = form.watch('skillSet');

  if (skillSet?.length) {
    onChangeWidth('lg');
  } else {
    onChangeWidth('sm');
  }

  const type = form.watch('type');

  useEffect(() => {
    const additionalInformation = form.watch('additionalInformation');

    if (type === 'replacement') {
      const message = formatMessage({ id: AppMessages['requestNeed.absenceReplacement.additionalInfo'] });

      const newAdditionalInfo =
        !additionalInformation?.length || additionalInformation === message
          ? message
          : `${additionalInformation}\n\n${message}`;

      form.setValue('additionalInformation', newAdditionalInfo);
    }
  }, [type, form]);

  if (isProjectLoading) {
    return <Loader fullHeight={false} />;
  }

  return (
    <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
      <DialogInnerWrapper
        title={formatMessage({ id: AppMessages['requestNeed.title'] })}
        loading={isLoading}
        onClose={onClose}
        onSubmit={form.handleSubmit(onSubmit)}
        isFormValid={form.formState.isValid}
      >
        <FormProvider {...form}>
          <NeedForm loading={isLoading} projectId={projectId} />
        </FormProvider>
      </DialogInnerWrapper>
    </DialogClickAwayListenerWrapper>
  );
};
