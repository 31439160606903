import { AdditionalValuesModal } from '../additionalValuesModal/AdditionalValuesModal';

import { EditChargeModalProps } from './EditChargeModal.types';
import { EditChargeContent } from './editChargeContent/EditChargeContent';

export const EditChargeModal = ({
  open,
  onClose,
  workStatementId,
  charge,
  billingCycleId,
  invoiceId,
}: EditChargeModalProps) => (
  <AdditionalValuesModal open={open} onClose={onClose}>
    <EditChargeContent
      onClose={onClose}
      workStatementId={workStatementId}
      charge={charge}
      billingCycleId={billingCycleId}
      invoiceId={invoiceId}
    />
  </AdditionalValuesModal>
);
