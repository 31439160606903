import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useEditNeed } from 'hooks/useEditNeed/useEditNeed';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProject } from 'hooks/useProject/useProject';
import { AppMessages } from 'i18n/messages';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { needSchema } from '../../schema/Need.schema';
import { NeedFormDto, NeedPayload } from '../../Need.types';
import { NeedForm } from '../../form/NeedForm';
import { Seniority } from 'api/types/Seniority.enum';
import { sortSkillsByWeight } from 'utils/sortSkillsByWeight';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { formatDate, parseDate } from 'utils/dateUtils';
import { Loader } from 'ui/loader/Loader';

import { EditNeedContentProps } from './EditNeedContent.types';

export const EditNeedContent = ({ projectId, need, onClose }: EditNeedContentProps) => {
  const { formatMessage } = useLocale();
  const { mutateAsync, isLoading } = useEditNeed(projectId, need.id);
  const { data: projectData, isLoading: isProjectLoading } = useProject(projectId);

  const isCustomFte = need?.fte !== 20 && need?.fte !== 40;

  const defaultValues: NeedFormDto = {
    type: need.type,
    replacedDays: need.replacedDays?.toString(),
    role: need.role,
    seniority: need.seniority && need.seniority !== Seniority.withoutLevel ? need.seniority : '',
    seniorityEnabled: need.seniority && need.seniority !== Seniority.withoutLevel ? true : false,
    fte: !isCustomFte ? JSON.stringify(need.fte) : 'other',
    fteCustom: isCustomFte ? JSON.stringify(need.fte) : '',
    additionalInformation: need.additionalInformation || '',
    startDate: parseDate(need.startDate),
    endDate: need.endDate ? parseDate(need.endDate) : null,
    skillSet: need.skillSet || [],
  };

  const form = useIntlForm<NeedFormDto>({
    defaultValues,
    mode: 'onSubmit',
    resolver: joiResolver(needSchema(projectData?.startDate)),
  });

  const onSubmit = async (need: NeedFormDto) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const skillSet = need.skillSet?.map(({ isDraft, ...skill }) => skill);

    const payload: NeedPayload = {
      type: need.type,
      replacedDays: need.replacedDays,
      role: need.role,
      fte: need.fte,
      startDate: need.startDate && formatDate(need.startDate),
    };

    if (need.fteCustom) {
      payload.fte = need.fteCustom;
    }

    if (need.seniority) {
      payload.seniority = need.seniority;
    } else {
      payload.seniority = Seniority.withoutLevel;
    }

    if (need.additionalInformation) {
      payload.additionalInformation = need.additionalInformation;
    }

    payload.endDate = need.endDate ? formatDate(need.endDate) : null;

    if (skillSet && skillSet.length > 0) {
      payload.skillSet = sortSkillsByWeight(skillSet);
    }

    await mutateAsync(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  if (isProjectLoading) {
    return <Loader fullHeight={false} />;
  }

  return (
    <DialogInnerWrapper
      title={formatMessage({ id: AppMessages['editNeed.title'] })}
      loading={isLoading}
      onClose={onClose}
      onSubmit={form.handleSubmit(onSubmit)}
      isFormValid={form.formState.isValid}
      submitText={formatMessage({ id: AppMessages['button.save'] })}
    >
      <FormProvider {...form}>
        <NeedForm
          projectId={projectId}
          loading={isLoading}
          variant={'edit'}
          replacedAssignmentId={need.replacedAssignment?.id}
        />
      </FormProvider>
    </DialogInnerWrapper>
  );
};
