import { Dialog } from '@mui/material';

import { useDialog } from 'hooks/useDialog/useDialog';

import { ConvertLeadModalProps } from './ConvertLeadModal.types';
import { ConvertLeadContent } from './convertLeadContent/ConvertLeadContent';
import { SuccessModal } from './sucessModal/SuccessModal';

export const ConvertLeadModal = ({ projectId, projectName, startDate, open, onClose }: ConvertLeadModalProps) => {
  const { isOpen: isSuccessModalOpen, setOpen: setSuccessModalOpen, setClose: setSuccessModalClose } = useDialog();

  return (
    <>
      <Dialog maxWidth="sm" open={open} onClose={onClose}>
        <ConvertLeadContent
          onClose={onClose}
          projectId={projectId}
          startDate={startDate}
          projectName={projectName}
          onSuccess={() => {
            setSuccessModalOpen();
            onClose();
          }}
        />
      </Dialog>
      <SuccessModal open={isSuccessModalOpen} onClose={setSuccessModalClose} />
    </>
  );
};
