import { Divider } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { SearchbarDebounced } from 'ui/searchbarDebounced/SearchbarDebounced';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { PeopleActiveFilters } from 'shared/people/peopleFilters/peopleActiveFilters/PeopleActiveFilters';

import * as styles from './PeopleListTopbar.styles';

export const PeopleListTopbar = () => {
  const { formatMessage } = useLocale();
  const { filters, setFilterValue } = usePeopleFilters();

  const handleSearch = (value: string) => {
    setFilterValue('search', value);
  };

  const handleClear = () => {
    setFilterValue('search', '');
  };

  return (
    <>
      <SearchbarDebounced
        label={formatMessage({ id: AppMessages['people.searchbar.label'] })}
        name="search"
        onChange={handleSearch}
        value={filters.search || ''}
        isClearable={!!filters.search}
        onClear={handleClear}
      />
      <Divider sx={styles.divider} />
      <PeopleActiveFilters />
    </>
  );
};
