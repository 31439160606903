import { useLocation } from 'react-router-dom';

import { ProjectStage } from 'api/types/ProjectStage.enum';
import { useClient } from 'hooks/useClient/useClient';
import { AppRoute } from 'routing/AppRoute.enum';
import {
  formatDate,
  getCurrentDayStartDate,
  isDateBefore,
  parseDate,
  START_OF_BILLING_CYCLE_FEATURE,
} from 'utils/dateUtils';

export const useGetSelectedBillingCycleInitialDate = (endDate: string | null): string => {
  const { pathname } = useLocation();
  const { clientDetails } = useClient();

  const isClientRoute = pathname.includes(AppRoute.clients);

  let endDateToCheck = endDate;

  if (isClientRoute) {
    const activeProjects = clientDetails
      ? clientDetails.client.projects.filter(({ stage }) =>
          [ProjectStage.ongoing, ProjectStage.closing].includes(stage),
        )
      : [];

    endDateToCheck = activeProjects.reduce((acc, curr) => {
      if (!curr.endDate) {
        return curr;
      }

      return isDateBefore(parseDate(acc.endDate), parseDate(curr.endDate)) ? curr : acc;
    }).endDate;
  }

  const isEndDateBeforeBCFeatureStart =
    !!endDateToCheck && isDateBefore(parseDate(endDateToCheck), START_OF_BILLING_CYCLE_FEATURE);

  const isEndDateBeforeToday = !!endDateToCheck && isDateBefore(parseDate(endDateToCheck), getCurrentDayStartDate());

  if (isEndDateBeforeToday) {
    if (isEndDateBeforeBCFeatureStart) {
      return formatDate(START_OF_BILLING_CYCLE_FEATURE);
    }

    if (endDateToCheck) {
      return endDateToCheck;
    }
  }

  return formatDate(getCurrentDayStartDate());
};
