import { Box, Stack } from '@mui/material';
import { DataDisplay, Grid } from 'lux/components';
import { WorkEndIcon, WorkStartIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './AssignmentWorkTimeInfo.styles';
import { AssignmentWorkTimeInfoProps } from './AssignmentWorkTimeInfo.types';

export const AssignmentWorkTimeInfo = ({ startDate, endDate }: AssignmentWorkTimeInfoProps) => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.workTimeTab}>
      <Grid container display="flex" gutter={1} spacing={0}>
        <Grid item xs={6} display="flex">
          <Stack direction="row" spacing={1} py={1}>
            <Box sx={styles.icon}>
              <WorkStartIcon />
            </Box>
            <DataDisplay header={formatMessage({ id: AppMessages['card.workTimeInfo.workStarts'] })}>
              {startDate}
            </DataDisplay>
          </Stack>
        </Grid>
        <Grid item xs={6} display="flex">
          <Stack direction="row" spacing={1} py={1}>
            <Box sx={styles.icon}>
              <WorkEndIcon />
            </Box>
            <DataDisplay header={formatMessage({ id: AppMessages['card.workTimeInfo.workEnds'] })}>
              {endDate || '-'}
            </DataDisplay>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
