import { Dialog } from '@mui/material';

import { useDialog } from 'hooks/useDialog/useDialog';

import { ConfirmUncertainRemovalModal } from './confirmUncertainRemovalModal/ConfirmUncertainRemovalModal';
import { EditUncertainContent } from './editUncertainContent/EditUncertainContent';
import { EditUncertainModalProps } from './EditUncertainModal.types';

export const EditUncertainModal = ({ open, onClose }: EditUncertainModalProps) => {
  const { isOpen: isConfirmModalOpen, setOpen: setConfirmModalOpen, setClose: setConfirmModalClose } = useDialog();

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <EditUncertainContent onClose={onClose} onRemove={setConfirmModalOpen} />
      </Dialog>
      <ConfirmUncertainRemovalModal open={isConfirmModalOpen} onClose={setConfirmModalClose} />
    </>
  );
};
