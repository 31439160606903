import { useState, useMemo, useLayoutEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import {
  addMonthToDate,
  formatDate,
  getCurrentDayStartDate,
  getStartOfMonthDate,
  isDateAfter,
  isDateBefore,
  isDateInSameMonth,
  parseDate,
  parseISODate,
  subtractMonthFromDate,
} from 'utils/dateUtils';
import { useBillingSummary } from 'hooks/useBillingSummary/useBillingSummary';
import { ProjectBillingSummaryDataContext } from '../projectBillingSummaryDataContext/ProjectBillingSummaryDataContext';
import { useReport } from 'hooks/useReport/useReport';
import { useProjectBillingSummary } from 'hooks/useProjectBillingSummary/useProjectBillingSummary';
import { projectsKeys } from 'utils/queryKeys';
import {
  ConfirmInvoiceError,
  ProjectBillingSummaryDataContextType,
} from '../projectBillingSummaryDataContext/ProjectBillingSummaryDataContext.types';
import { InvoiceStatusEnum } from 'api/types/BillingSummary.types';
import { useSessionStorage } from 'hooks/useSessionStorage/useSessionStorage';
import { useLeaveRoute, userNavigationState } from 'hooks/useLeaveRoute/useLeaveRoute';
import { SelectedBillingCycleKey } from 'api/types/Timesheet.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { useGetSelectedBillingCycleInitialDate } from 'hooks/useGetSelectedBillingCycleInitialDate/useGetSelectedBillingCycleInitialDate';

import { isStartOrEndDateAfterJanuary } from './ProjectBillingSummaryDataContextController.utils';
import { ProjectBillingSummaryDataContextControllerProps } from './ProjectBillingSummaryDataContextController.types';

export const confirmInvoiceErrorDefaultState: ConfirmInvoiceError = {
  message: null,
  errorType: null,
};

export const ProjectBillingSummaryDataContextController = ({
  children,
}: ProjectBillingSummaryDataContextControllerProps) => {
  const {
    projectDetails: {
      stage,
      id: projectId,
      startDate,
      endDate,
      client: { id: clientId },
    },
  } = useProjectDetails();
  const { clientId: paramsClientId } = useParams();

  const selectedBillingCycleInitialDate = useGetSelectedBillingCycleInitialDate(endDate);

  const [selectedBillingCycle, setSelectedBillingCycle] = useSessionStorage(
    SelectedBillingCycleKey.SelectedBillingCycle,
    userNavigationState(clientId, projectId, selectedBillingCycleInitialDate),
  );
  const [isNavigationReset] = useLeaveRoute(
    projectId,
    selectedBillingCycle.projectId,
    selectedBillingCycle.clientId,
    paramsClientId,
  );

  const [selectedBIDate, setSelectedBIDate] = useState<Date>(() =>
    getStartOfMonthDate(parseISODate(selectedBillingCycle.date)),
  );

  useLayoutEffect(() => {
    if (isNavigationReset) {
      setSelectedBIDate(parseDate(selectedBillingCycleInitialDate));
    }
  }, [isNavigationReset]);

  const [confirmInvoiceError, setConfirmInvoiceError] = useState<ConfirmInvoiceError>(confirmInvoiceErrorDefaultState);
  const isSelectedMonthAfterEndDate = endDate && isDateAfter(selectedBIDate, parseDate(endDate));
  const isSelectedMonthBeforeStartDateAndNotCurrent =
    isDateBefore(selectedBIDate, parseDate(startDate)) && !isDateInSameMonth(selectedBIDate, parseDate(startDate));

  const enableBillingSummaryFetch =
    ![ProjectStage.lead, ProjectStage.archived].includes(stage) &&
    isStartOrEndDateAfterJanuary(startDate, endDate) &&
    !isSelectedMonthAfterEndDate &&
    !isSelectedMonthBeforeStartDateAndNotCurrent;

  const { groupBy } = useProjectBillingSummary();
  const {
    data: billingSummaryData,
    isLoading,
    fetchStatus,
    error,
  } = useBillingSummary(projectId, formatDate(selectedBIDate), groupBy, enableBillingSummaryFetch);
  const { isReport, reportDate } = useReport();

  // isLoadingAndEnabled from new RQ abstraction from latest starter
  const isLoadingBillingSummaryData = isLoading && fetchStatus !== 'idle';

  const billingCycleStatus = billingSummaryData?.billingCycle?.status;
  const invoiceStatus = billingSummaryData?.currentData?.workStatements[0]?.invoice?.status;
  const invoiceNote = billingSummaryData?.currentData?.workStatements[0]?.invoice?.note;
  const queryClient = useQueryClient();
  const invalidateBillingSummaryCache = () =>
    queryClient.invalidateQueries(projectsKeys.projectBillingSummary(projectId, formatDate(selectedBIDate), groupBy));
  const billingCycle =
    billingSummaryData?.billingCycle && billingCycleStatus ? billingSummaryData.billingCycle : undefined;

  const lastManualUpdate = billingSummaryData?.lastManualUpdate;

  const snapshotCreateDate = billingSummaryData?.snapshotData?.createdAt;
  const currentDataWorkStatements = billingSummaryData?.currentData?.workStatements;
  const snapshotDataWorkStatements = billingSummaryData?.snapshotData?.workStatements;
  const showWorkStatementSnapshot = Boolean(billingSummaryData?.snapshotData);
  const workStatements = showWorkStatementSnapshot ? snapshotDataWorkStatements : currentDataWorkStatements;

  const billingSummaryAssignments = workStatements && workStatements.flatMap((ws) => ws.assignments);

  useLayoutEffect(() => {
    if (isReport && reportDate) {
      setSelectedBIDate(new Date(reportDate));
    }
  }, [isReport, reportDate]);

  const resetInvoiceError = () => {
    setConfirmInvoiceError(confirmInvoiceErrorDefaultState);
  };

  const selectDateAndResetErrors = (date: Date) => {
    setSelectedBIDate(date);
    resetInvoiceError();
  };

  const saveBillingSummaryMonthStartDate = (monthStartDate: Date) => {
    selectDateAndResetErrors(monthStartDate);
    setSelectedBillingCycle(userNavigationState(clientId, projectId, monthStartDate.toISOString()));
  };

  const goToPreviousMonth = () => {
    const previousMonthStartDate = subtractMonthFromDate(selectedBIDate);
    saveBillingSummaryMonthStartDate(previousMonthStartDate);
  };

  const goToNextMonth = () => {
    const nextMonthStartDate = addMonthToDate(selectedBIDate);
    saveBillingSummaryMonthStartDate(nextMonthStartDate);
  };

  const goToCurrentMonth = () => {
    const firstDayOfCurrentMonth = getStartOfMonthDate(getCurrentDayStartDate());
    saveBillingSummaryMonthStartDate(firstDayOfCurrentMonth);
  };

  const isInvoiceEditBlocked = Boolean(
    invoiceStatus &&
      [
        InvoiceStatusEnum.approved_by_pm,
        InvoiceStatusEnum.paid,
        InvoiceStatusEnum.sent,
        InvoiceStatusEnum.invoice_issued,
      ].includes(invoiceStatus),
  );

  const value: ProjectBillingSummaryDataContextType = useMemo(
    () => ({
      isLoadingBillingSummaryData,
      billingCycle,
      workStatements: workStatements ?? [],
      dataMismatch: snapshotDataWorkStatements?.[0]?.dataMismatch,
      snapshotCreateDate,
      showWorkStatementSnapshot,
      projectId,
      lastManualUpdate,
      invalidateBillingSummaryCache,
      selectedDate: selectedBIDate,
      isReport,
      goToPreviousMonth,
      goToNextMonth,
      goToCurrentMonth,
      billingSummaryAssignments,
      billingSummaryError: error,
      confirmInvoiceError,
      setConfirmInvoiceError,
      resetInvoiceError,
      isInvoiceEditBlocked,
      invoiceNote,
      setSelectedBIDate,
      selectedBIDate,
      groupBy,
    }),
    [
      isLoadingBillingSummaryData,
      billingCycle,
      workStatements,
      snapshotDataWorkStatements?.[0]?.dataMismatch,
      snapshotCreateDate,
      showWorkStatementSnapshot,
      projectId,
      lastManualUpdate,
      invalidateBillingSummaryCache,
      selectedBIDate,
      isReport,
      goToPreviousMonth,
      goToNextMonth,
      goToCurrentMonth,
      billingSummaryAssignments,
      error,
      confirmInvoiceError,
      setConfirmInvoiceError,
      resetInvoiceError,
      isInvoiceEditBlocked,
      invoiceNote,
      setSelectedBIDate,
      selectedBIDate,
      groupBy,
    ],
  );

  return (
    <ProjectBillingSummaryDataContext.Provider value={value}>{children}</ProjectBillingSummaryDataContext.Provider>
  );
};
