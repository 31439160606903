import * as React from 'react';
import { HTMLAttributes, useMemo } from 'react';

import { AutocompleteRenderGetTagProps, AutocompleteRenderOptionState } from '@mui/material';
import { AutocompleteGeneric, Chip, MenuItem } from 'lux/components';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { usePeople } from 'hooks/usePeople/usePeople';
import { AppMessages } from 'i18n/messages';

import { mapPeopleOptions } from './PeopleFilter.utils';
import type { Option, PeopleFilterTypes } from './PeopleFilter.types';

const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: Option, state: AutocompleteRenderOptionState) => (
  <MenuItem {...props} selected={state.selected}>
    {option.label}
  </MenuItem>
);

const renderTags = (value: Option[], getTagProps: AutocompleteRenderGetTagProps) =>
  value.map((tag, index) => {
    const { key, ...rest } = getTagProps({ index });
    return (
      <Chip
        {...rest}
        key={key}
        label={tag.label}
        sx={{
          '&.MuiChip-root': {
            margin: 0.5,
          },
        }}
      />
    );
  });

export const PeopleFilter = ({ variant }: PeopleFilterTypes) => {
  const { formatMessage } = useLocale();
  const {
    filters: { people: peopleFilters },
    setFilterValue,
  } = useProjectsFilters();
  const { data, isLoading } = usePeople();

  const handleFilterChange = (e: React.SyntheticEvent, values: Array<Option>) => {
    const newValues = values.map((option) => option.value);
    setFilterValue('people', newValues);
  };

  const options = useMemo(() => mapPeopleOptions(data), [data]);
  const selectedValues = useMemo(
    () => options.filter((option) => peopleFilters.includes(option.value)),
    [options, peopleFilters],
  );

  return (
    <Filter variant={variant} label={formatMessage({ id: AppMessages['projects.filters.people.label'] })}>
      <AutocompleteGeneric
        onChange={handleFilterChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        multiple
        fullWidth
        size="small"
        options={options}
        value={selectedValues}
        getOptionLabel={(option) => option.label}
        renderOption={renderOption}
        renderTags={renderTags}
        disabled={isLoading}
      />
    </Filter>
  );
};
