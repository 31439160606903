import { useAuth } from 'hooks/useAuth/useAuth';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';
import { peopleKeys } from 'utils/queryKeys';
import { getPeople } from 'api/actions/getPeople/getPeople';
import { GetPeopleParams, GetPeopleResponse } from 'api/actions/getPeople/getPeople.types';
import { EmploymentTypeFilter } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { convertRateToInteger } from 'utils/convertRate';
import { getNextPageParam } from 'api/utils/getNextPageParam';
import { parseSortFilter } from 'utils/filters/filters';

import { UsePeopleParams } from './useInfinitePeople.types';

const employmentTypeMap = {
  [EmploymentTypeFilter.uncertain]: 'UNCERTAIN',
  [EmploymentTypeFilter.tshEmployee]: Object.values(EmploymentType).filter(
    (employmentType) => employmentType !== EmploymentType.LTSH,
  ),
  [EmploymentTypeFilter.subcontractor]: EmploymentType.LTSH,
};

export const useInfinitePeople = (params: UsePeopleParams) => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...peopleKeys.peopleList, params];

  let args: GetPeopleParams = {};

  if (params?.search) {
    args = { ...args, name: params.search };
  }

  if (params?.sort) {
    const { field, order } = parseSortFilter(params.sort);

    args = {
      ...args,
      [`sort[${field}]`]: order,
    };
  }

  if (params?.departments) {
    args = { ...args, department_code: params.departments };
  }

  if (params?.seniorities) {
    args = { ...args, seniority: params.seniorities };
  }

  if (params?.fte) {
    args = { ...args, fte: Number(params.fte) };
  }

  if (params?.status) {
    args = { ...args, availability_status: params.status };
  }

  if (params?.skills) {
    args = { ...args, skills: params.skills.filter((skill) => skill !== '') };
  }

  if (params?.rateMin) {
    args = { ...args, 'rate[min]': String(convertRateToInteger(params.rateMin)) };
  }

  if (params?.rateMax) {
    args = { ...args, 'rate[max]': String(convertRateToInteger(params.rateMax)) };
  }

  if (params?.employmentType) {
    args = {
      ...args,
      employment_type: params.employmentType.flatMap((type) => {
        if (!Object.values(EmploymentTypeFilter).includes(type as EmploymentTypeFilter)) {
          return [];
        }
        return employmentTypeMap[type as EmploymentTypeFilter];
      }),
    };
  }

  if (params?.availabilityFrom) {
    args = { ...args, 'availability[from]': params.availabilityFrom };
  }

  if (params?.availabilityTo) {
    args = { ...args, 'availability[to]': params.availabilityTo };
  }

  if (params?.includeUnemployed) {
    args = { ...args, include_unemployed: params.includeUnemployed === 'true' };
  }

  if (params?.nonDevRoles) {
    args = { ...args, non_dev_roles: params.nonDevRoles === 'true' };
  }

  return useInfiniteQuery<GetPeopleParams, GetPeopleResponse>(queryKey, getPeople, {
    cursorKey: 'page',
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    startPage: 1,
    args: {
      ...args,
      limit: params.size || 50,
    },
    getNextPageParam: getNextPageParam,
  });
};
