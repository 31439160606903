import { Dialog } from '@mui/material';

import { AddBillingCycleForm } from './addBillingCycleForm/AddBillingCycleForm';
import { AddBillingCycleModalProps } from './AddBillingCycleModal.types';

export const AddBillingCycleModal = ({ open, onClose }: AddBillingCycleModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <AddBillingCycleForm onClose={onClose} />
  </Dialog>
);
