import { Stack } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { Loader } from 'ui/loader/Loader';
import { ProjectAccordion } from '../projectAccordion/ProjectAccordion';

import { PersonAssignmentsProps } from './PersonAssignments.types';

export const PersonAssignments = ({ assignments, isLoading }: PersonAssignmentsProps) => {
  const { formatMessage } = useLocale();

  if (isLoading) {
    return <Loader fullHeight={false} />;
  }

  if (assignments.length === 0) {
    return (
      <EmptyState
        illustration="relaxPeople"
        title={formatMessage({ id: AppMessages['personDetails.projects.assignments.empty.title'] })}
        message={formatMessage({ id: AppMessages['personDetails.projects.assignments.empty.message'] })}
      />
    );
  }

  return (
    <Stack gap={3}>
      {assignments.map(({ id, project, fte, person, startDate, endDate }) => (
        <ProjectAccordion
          key={id}
          project={project}
          fte={fte}
          rate={person.rate}
          currency={person.currency}
          assignment={{ startDate, endDate }}
        />
      ))}
    </Stack>
  );
};
