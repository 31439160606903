import { Badge } from 'lux/components';

import { Translation } from 'ui/translation/Translation';
import { hideInPdf } from 'app/project/projectReport/ProjectReport.styles';

import { getInvoiceStatusColor, getInvoiceStatusTranslation } from './InvoiceStatusBadge.utils';
import { InvoiceStatusBadgeProps } from './InvoiceStatusBadge.types';

export const InvoiceStatusBadge = ({
  billingCycleEndDate,
  currentDate,
  invoice,
  status,
  showInvoiceNumber = true,
}: InvoiceStatusBadgeProps) => {
  const color = getInvoiceStatusColor(billingCycleEndDate, currentDate, invoice, status);
  const translation = getInvoiceStatusTranslation(billingCycleEndDate, currentDate, invoice, status);
  const invoiceNumber = showInvoiceNumber ? `: ${invoice.number}` : undefined;

  return (
    <Badge
      isStatic
      color={color}
      badgeContent={<Translation id={translation} values={{ number: invoiceNumber }} />}
      sx={hideInPdf}
    />
  );
};
