import { Divider } from '@mui/material';

import { AppMessages, Translation } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { ActiveFilter } from 'ui/activeFilters/ActiveFilters.types';
import { ActiveFilters } from 'ui/activeFilters/ActiveFilters';
import { filtersData } from 'config/data/projects/filters';
import { ProjectsFilters } from 'context/projectsFilters/projectsFiltersContext/ProjectsFiltersContext.types';
import { createActiveFilters } from 'ui/activeFilters/createActiveFilters';
import { useProjectsListConfig } from 'hooks/useProjectsListConfig/useProjectsListConfig';
import { SearchbarDebounced } from 'ui/searchbarDebounced/SearchbarDebounced';
import { usePeople } from 'hooks/usePeople/usePeople';
import { mapPeopleOptions } from 'shared/project/projectsList/projectsGrid/projectsFilters/people/PeopleFilter.utils';

import * as styles from './ProjectsListTopbar.styles';

const translationsBasedOnName: { name: string; labelTranslation: Translation }[] = [
  { name: 'search', labelTranslation: 'filters.search.value' },
];

export const ProjectsListTopbar = () => {
  const { formatMessage } = useLocale();
  const { filters, toggleFilter, setFilterValue } = useProjectsFilters();
  const { inputSearchLabel } = useProjectsListConfig();
  const { isCLientChipPreventedForDeletion } = useProjectsListConfig();
  const { data, isLoading } = usePeople();

  const handleChange = (value: string) => {
    setFilterValue('search', value);
  };

  const handleClear = () => {
    setFilterValue('search', '');
  };

  const handleFilterDelete = ({ name, value }: ActiveFilter) => {
    if (isCLientChipPreventedForDeletion && name === 'clientName') {
      return;
    }
    toggleFilter(name as keyof ProjectsFilters, value);
  };

  const peopleFilter = mapPeopleOptions(data);

  const showPeopleFilter = isLoading ? 'people' : undefined;

  const translationsBasedOnValue = {
    ...filtersData,
    people: peopleFilter,
  };

  const activeFilterData = createActiveFilters(filters, {
    exclude: ['sort', showPeopleFilter],
    translationsBasedOnValue,
    translationsBasedOnName,
    disableDeletion: ['clientName'],
  });

  const inputLabel = inputSearchLabel ?? 'projects.searchbar.label';

  return (
    <>
      <SearchbarDebounced
        name="search"
        onChange={handleChange}
        label={formatMessage({ id: AppMessages[inputLabel] })}
        value={filters.search || ''}
        isClearable={!!filters.search}
        onClear={handleClear}
      />
      <Divider sx={styles.divider} />
      <ActiveFilters activeFiltersData={activeFilterData} onFilterDelete={handleFilterDelete} />
    </>
  );
};
