import { Box, Dialog } from '@mui/material';

import { EditAssignmentContent } from './editAssignmentContent/EditAssignmentContent';
import * as styles from './EditAssignmentModal.styles';
import type { EditAssignmentModalProps } from './EditAssignmentModal.types';
import { EditAssignmentSidebar } from './editAssignmentSidebar/EditAssignmentSidebar';

export const EditAssignmentModal = ({ assignmentData, onClose, open }: EditAssignmentModalProps) => (
  <Dialog maxWidth="sm" open={open} onClose={onClose} sx={styles.dialog}>
    <EditAssignmentSidebar assignmentData={assignmentData} />

    <Box component="section" sx={styles.content}>
      <EditAssignmentContent onClose={onClose} assignmentData={assignmentData} />
    </Box>
  </Dialog>
);
