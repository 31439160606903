import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseIcon } from 'lux/icons';

import { PersonAvailabilityStatus } from 'api/types/PersonAvailabilityStatus.enum';
import { DefaultPeopleFilters } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';
import { PeopleFiltersContextController } from 'context/peopleFilters/peopleFiltersContextController/PeopleFiltersContextController';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './PeopleActionsModal.styles';
import { PeopleActionsModalProps, PeopleActionsModalStep } from './PeopleActionsModal.types';
import { PeopleListGrid } from './peopleListGrid/PeopleListGrid';

export const PeopleActionsModal = ({
  onClose,
  isOpen,
  personAction,
  peopleListTitle,
  currentStepName = 'peopleList',
  steps,
  isVisible = true,
  defaultPeopleFilters,
}: PeopleActionsModalProps) => {
  const { formatMessage } = useLocale();

  const peopleFilters: DefaultPeopleFilters = {
    status: [PersonAvailabilityStatus.available, PersonAvailabilityStatus.availableSoon],
    includeUnemployed: 'false',
    ...defaultPeopleFilters,
  };

  const peopleListStepData: PeopleActionsModalStep = {
    name: 'peopleList',
    title: peopleListTitle || formatMessage({ id: AppMessages['card.need.peopleList.propose.title'] }),
    content: (
      <Box display="flex" overflow="hidden" marginBottom={3}>
        <PeopleFiltersContextController peopleFilters={peopleFilters}>
          <PeopleListGrid personAction={personAction} />
        </PeopleFiltersContextController>
      </Box>
    ),
  };

  const currentStep = steps.find(({ name }) => name === currentStepName) || peopleListStepData;
  const { title, content, actions, modalMaxWidth } = currentStep;

  return (
    <Dialog
      sx={{ visibility: isVisible ? 'visible' : 'hidden' }}
      maxWidth={modalMaxWidth || 'xl'}
      fullWidth
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={styles.dialogTitle} variant="h5">
        {title}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
