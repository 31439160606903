import Joi from 'joi';

import { FormSeniority } from 'api/types/Seniority.enum';
import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';
import { MAX_SUPPORTED_DATE, ZERO_UNIX_DATE, formatDate, parseDate } from 'utils/dateUtils';
import { NeedType } from 'api/types/NeedType.enum';

const SKILLSET_NOTE_MAX_LENGTH = 100;

export const needSchema = (projectStartDate?: string) => {
  const { formatMessage } = intl.currentIntl;

  const parseSkillNameToCompare = (name: string) => name.replace(/\s/g, '').toLowerCase();

  return Joi.object().keys({
    type: Joi.string()
      .valid(...Object.values(NeedType))
      .required(),
    replacedDays: Joi.when('type', {
      is: 'replacement',
      then: Joi.number().min(1).max(999).allow(null),
      otherwise: Joi.number().allow('').optional().strip(),
    }).messages({
      'number.base': formatMessage(
        { id: AppMessages['validation.number'] },
        { label: formatMessage({ id: AppMessages['need.form.replacedDays'] }) },
      ),
      'number.max': formatMessage(
        { id: AppMessages['validation.max'] },
        {
          label: formatMessage({ id: AppMessages['need.form.replacedDays'] }),
          limit: 999,
        },
      ),
      'number.min': formatMessage(
        { id: AppMessages['validation.min'] },
        {
          label: formatMessage({ id: AppMessages['need.form.replacedDays'] }),
          limit: 1,
        },
      ),
    }),
    role: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['need.form.role'] }) },
        ),
      }),
    seniorityEnabled: Joi.boolean().optional().strip(),
    seniority: Joi.when('seniorityEnabled', {
      is: true,
      then: Joi.string()
        .valid(...Object.values(FormSeniority))
        .required(),
      otherwise: Joi.string().allow('').optional().strip(),
    }),
    fte: Joi.alternatives().try(Joi.number(), Joi.string()).required(),
    fteCustom: Joi.when('fte', {
      is: 'other',
      then: Joi.number().min(1).max(40).required(),
      otherwise: Joi.number().allow('').optional().strip(),
    }).messages({
      'number.base': formatMessage(
        { id: AppMessages['validation.number'] },
        { label: formatMessage({ id: AppMessages['need.form.fteCustom'] }) },
      ),
      'number.max': formatMessage(
        { id: AppMessages['validation.max'] },
        {
          label: formatMessage({ id: AppMessages['need.form.fteCustom'] }),
          limit: 40,
        },
      ),
      'number.min': formatMessage(
        { id: AppMessages['validation.min'] },
        {
          label: formatMessage({ id: AppMessages['need.form.fteCustom'] }),
          limit: 1,
        },
      ),
    }),
    additionalInformation: Joi.string().max(255).allow('').optional(),
    startDate: Joi.date()
      .required()
      .min(projectStartDate ? parseDate(projectStartDate) : ZERO_UNIX_DATE)
      .max(MAX_SUPPORTED_DATE)
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['need.form.availableFrom'] }) },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.min'],
          },

          {
            label: formatMessage({ id: AppMessages['need.form.availableFrom'] }),
            limit: projectStartDate || formatDate(ZERO_UNIX_DATE),
          },
        ),
        'date.max': formatMessage(
          {
            id: AppMessages['validation.max'],
          },

          {
            label: formatMessage({ id: AppMessages['need.form.availableFrom'] }),
            limit: formatDate(MAX_SUPPORTED_DATE),
          },
        ),
      }),
    endDate: Joi.date()
      .allow(null)
      .optional()
      .min(Joi.ref('startDate'))
      .max(MAX_SUPPORTED_DATE)
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.incorrect'] },
          { label: formatMessage({ id: AppMessages['need.form.availableTo.id'] }) },
        ),
        'date.max': formatMessage(
          {
            id: AppMessages['validation.max'],
          },

          {
            label: formatMessage({ id: AppMessages['need.form.availableTo.id'] }),
            limit: formatDate(MAX_SUPPORTED_DATE),
          },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.min'],
          },

          {
            label: formatMessage({ id: AppMessages['need.form.availableTo.id'] }),
            limit: `"${formatMessage({ id: AppMessages['need.form.availableFrom'] })}"`,
          },
        ),
      }),
    skillSet: Joi.array()
      .items(
        Joi.object().keys({
          name: Joi.string()
            .required()
            .messages({
              'string.empty': formatMessage(
                { id: 'validation.empty' },
                { label: formatMessage({ id: AppMessages['need.form.skillSet.name'] }) },
              ),
            }),
          weight: Joi.string()
            .required()
            .messages({
              'string.empty': formatMessage(
                { id: 'validation.empty' },
                { label: formatMessage({ id: AppMessages['need.form.skillSet.weight'] }) },
              ),
            }),
          note: Joi.string()
            .max(SKILLSET_NOTE_MAX_LENGTH)
            .allow('')
            .optional()
            .messages({
              'string.max': formatMessage(
                { id: 'validation.tooLong' },
                {
                  label: formatMessage({ id: AppMessages['need.form.skillSet.note.bare'] }),
                  limit: SKILLSET_NOTE_MAX_LENGTH,
                },
              ),
            }),
          isDraft: Joi.bool().optional(),
        }),
      )
      .unique((a, b) => parseSkillNameToCompare(a.name) === parseSkillNameToCompare(b.name))
      .messages({
        'array.unique': formatMessage({ id: 'need.form.skillSet.errors.name.notUnique' }),
      })
      .default([])
      .optional(),
  });
};
