import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useRemoveFutureRateCard } from 'hooks/useRemoveFutureRateCard/useRemoveFutureRateCard';
import { AppMessages } from 'i18n/messages';

import * as styles from './RemoveFutureRateCardModal.styles';
import { RemoveFutureRateCardModalProps } from './RemoveFutureRateCardModal.types';

export const RemoveFutureRateCardModal = ({ open, onClose, onSuccess, projectId }: RemoveFutureRateCardModalProps) => {
  const { formatMessage } = useLocale();
  const { mutate, isLoading } = useRemoveFutureRateCard(projectId);

  const handleSubmitButtonClick = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          onSuccess();
          onClose();
        },
      },
    );
  };

  return (
    <>
      <Dialog maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle>
          {formatMessage({ id: AppMessages['projectDetails.removeFutureRateCardModal.title'] })}
        </DialogTitle>
        <DialogContent>
          {formatMessage({ id: AppMessages['projectDetails.removeFutureRateCardModal.message'] })}
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button variant="text" sx={styles.cancelButton} onClick={onClose}>
            {formatMessage({ id: AppMessages['button.cancel'] })}
          </Button>
          <Button loading={isLoading} variant="contained" onClick={handleSubmitButtonClick} color="error">
            {formatMessage({ id: AppMessages['projectDetails.accordions.details.rateCard.tab.future.removeFuture'] })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
