import { useParams } from 'react-router-dom';

import { useProject } from 'hooks/useProject/useProject';
import { ProjectDetailsContext } from 'context/projectDetails/projectDetailsContext/ProjectDetailsContext';
import {
  EmployeeAssignments,
  ProjectDetailsValue,
} from 'context/projectDetails/projectDetailsContext/ProjectDetailsContext.types';
import { permitProjectEdition } from 'utils/permissions/permitProjectEdition';
import { checkIsLeadFromHubSpot } from 'utils/checkers/checkIsLeadFromHubSpot';
import { parseDate, addDaysToDate } from 'utils/dateUtils';
import { useExtremeConfirmedInvoice } from 'hooks/useExtremeConfirmedInvoice/useExtremeConfirmedInvoice';

import { ProjectDetailsControllerProps } from './ProjectDetailsContextController.types';

export const ProjectDetailsContextController = ({
  children,
  projectId: passedProjectId,
}: ProjectDetailsControllerProps) => {
  const { projectId } = useParams();

  if (!projectId && !passedProjectId) {
    throw new Error('Project id is required');
  }

  const { data } = useProject((projectId || passedProjectId) as string);
  const { latestInvoice } = useExtremeConfirmedInvoice((projectId || passedProjectId) as string);

  if (!data) return null;

  const groupTeamMembersByEmployee = (): EmployeeAssignments[] => {
    const uniqueTeamMembersMap = new Map();

    data.assignments.forEach((assignments) => {
      const employeeId = assignments.person.employeeId;
      const assignmentRange = { startDate: assignments.startDate, endDate: assignments.endDate };

      if (uniqueTeamMembersMap.has(employeeId)) {
        const existingTeamMember = uniqueTeamMembersMap.get(employeeId);
        existingTeamMember.assignments.push(assignmentRange);
      } else {
        const newTeamMember = {
          employeeId,
          assignments: [assignmentRange],
        };
        uniqueTeamMembersMap.set(employeeId, newTeamMember);
      }
    });

    return Array.from(uniqueTeamMembersMap.values());
  };

  const usersFte = data.assignments.flatMap((assignment) => ({
    id: assignment.id,
    fte: assignment.fte,
    startDate: assignment.startDate,
  }));

  const lastBillingCycleDate = latestInvoice?.billingCycles[0].endDate
    ? parseDate(latestInvoice?.billingCycles[0].endDate)
    : null;

  const allowChangeRatesFromDate = lastBillingCycleDate
    ? addDaysToDate(lastBillingCycleDate, 1)
    : parseDate(data.currentRateCard.appliesFrom);

  const contextValues: ProjectDetailsValue = {
    projectDetails: data,
    allowChangeRatesFromDate,
    allowProjectEdition: permitProjectEdition(data),
    isLeadFromHubSpot: checkIsLeadFromHubSpot(data),
    startDate: parseDate(data.startDate),
    endDate: data.endDate ? parseDate(data.endDate) : null,
    usersFte,
    employeeAssignments: groupTeamMembersByEmployee(),
  };

  return <ProjectDetailsContext.Provider value={contextValues}>{children}</ProjectDetailsContext.Provider>;
};
