import { ChangeEvent, useEffect, useRef } from 'react';

import { Box, ButtonBase } from '@mui/material';
import { CloseIcon, SearchIcon } from 'lux/icons';

import { DebouncedTextField } from 'ui/debouncedTextField/DebouncedTextField';

import * as styles from './SearchbarDebounced.styles';
import { SearchbarDebouncedProps } from './SearchbarDebounced.types';

export const SearchbarDebounced = ({ label, name, onChange, isClearable, onClear, value }: SearchbarDebouncedProps) => {
  const searchbarRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    searchbarRef.current?.focus();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onClear?.();
  };

  return (
    <Box sx={styles.searchbarWrapper}>
      <DebouncedTextField
        onChange={handleChange}
        name={name}
        value={value}
        delay={500}
        inputRef={searchbarRef}
        endAdornment={
          isClearable ? (
            <ButtonBase onClick={handleClear}>
              <CloseIcon />
            </ButtonBase>
          ) : (
            <SearchIcon />
          )
        }
        fullWidth
        label={label}
      />
    </Box>
  );
};
