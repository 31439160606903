import { Dialog } from '@mui/material';

import { AssignOrProposeAnywayModalProps } from './AssignOrProposeAnywayModal.types';
import { AssignOrProposeAnywayContent } from './assignOrProposeAnywayContent/AssignOrProposeAnywayContent';

export const AssignOrProposeAnywayModal = ({ open, ...props }: AssignOrProposeAnywayModalProps) => (
  <Dialog open={open} maxWidth="sm" onClose={props.onClose}>
    <AssignOrProposeAnywayContent {...props} />
  </Dialog>
);
