import { InvoiceListItem } from 'api/actions/getInvoices/getInvoices.types';
import { Invoice, InvoiceStatus } from 'api/types/BillingSummary.types';

export type InvoiceStatusBadgeProps = {
  invoice: Invoice | InvoiceListItem;
  status: InvoiceStatus;
  billingCycleEndDate: Date;
  currentDate: Date;
  showInvoiceNumber?: boolean;
};

export enum ColorsEnum {
  yellow = 'yellow',
  red = 'red',
  green = 'green',
  gray = 'gray',
  orange = 'orange',
}
