import { Dialog } from '@mui/material';

import { DeleteNeedProps } from '../Need.types';

import * as styles from './DeleteNeed.styles';
import { DeleteNeedContent } from './deleteNeedContent/DeleteNeedContent';

export const DeleteNeed = ({ projectId, needId, open, onClose, onSuccess }: DeleteNeedProps) => (
  <Dialog open={open} onClose={onClose} sx={styles.customDialog}>
    <DeleteNeedContent projectId={projectId} needId={needId} onClose={onClose} onSuccess={onSuccess} />
  </Dialog>
);
