import { useLayoutEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  addMonthToDate,
  formatDate,
  getArrayWithDatesInMonth,
  getCurrentDayStartDate,
  getStartOfMonthDate,
  isCurrentDate,
  isWeekendDate,
  parseDate,
  parseISODate,
  subtractMonthFromDate,
} from 'utils/dateUtils';
import {
  ProjectTimesheetContextType,
  ProjectTimesheetDay,
  SelectedTimesheetHoursCell,
} from '../projectTimesheetContext/ProjectTimesheetContext.types';
import { ProjectTimesheetContext } from '../projectTimesheetContext/ProjectTimesheetContext';
import { useSessionStorage } from 'hooks/useSessionStorage/useSessionStorage';
import { userNavigationState, useLeaveRoute } from 'hooks/useLeaveRoute/useLeaveRoute';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { SelectedBillingCycleKey } from 'api/types/Timesheet.types';
import { useGetSelectedBillingCycleInitialDate } from 'hooks/useGetSelectedBillingCycleInitialDate/useGetSelectedBillingCycleInitialDate';

import { ProjectTimesheetContextControllerProps } from './ProjectTimesheetContextController.types';

export const ProjectTimesheetContextController = ({ children }: ProjectTimesheetContextControllerProps) => {
  const {
    projectDetails: {
      id: projectId,
      endDate,
      client: { id: clientId },
    },
  } = useProjectDetails();
  const { clientId: paramsClientId } = useParams();

  const selectedBillingCycleInitialDate = useGetSelectedBillingCycleInitialDate(endDate);

  const [selectedBillingCycle, setSelectedBillingCycle] = useSessionStorage(
    SelectedBillingCycleKey.SelectedBillingCycle,
    userNavigationState(clientId, projectId, selectedBillingCycleInitialDate),
  );
  const [isNavigationReset] = useLeaveRoute(
    projectId,
    selectedBillingCycle.projectId,
    selectedBillingCycle.clientId,
    paramsClientId,
  );

  const [timesheetMonthStartDate, setTimesheetMonthStartDate] = useState<Date>(() =>
    getStartOfMonthDate(parseISODate(selectedBillingCycle.date)),
  );

  useLayoutEffect(() => {
    if (isNavigationReset) {
      setTimesheetMonthStartDate(parseDate(selectedBillingCycleInitialDate));
    }
  }, [isNavigationReset]);

  const timesheetMonthDatesParsed = useMemo<ProjectTimesheetDay[]>(
    () =>
      getArrayWithDatesInMonth(timesheetMonthStartDate).map((date) => ({
        value: date,
        number: formatDate(date, 'dd'),
        name: formatDate(date, 'iii'),
        isCurrent: isCurrentDate(date),
        isWeekend: isWeekendDate(date),
      })),
    [timesheetMonthStartDate],
  );

  const [selectedTimesheetHoursCell, setSelectedTimesheetHoursCell] = useState<SelectedTimesheetHoursCell | null>(null);

  const saveTimesheetMonthStartDate = (monthStartDate: Date) => {
    setTimesheetMonthStartDate(monthStartDate);
    setSelectedBillingCycle(userNavigationState(clientId, projectId, monthStartDate.toISOString()));
  };

  const goToPreviousMonth = () => {
    const previousMonthStartDate = subtractMonthFromDate(timesheetMonthStartDate);
    saveTimesheetMonthStartDate(previousMonthStartDate);
  };

  const goToNextMonth = () => {
    const nextMonthStartDate = addMonthToDate(timesheetMonthStartDate);
    saveTimesheetMonthStartDate(nextMonthStartDate);
  };

  const goToCurrentMonth = () => {
    const firstDayOfCurrentMonth = getStartOfMonthDate(getCurrentDayStartDate());
    saveTimesheetMonthStartDate(firstDayOfCurrentMonth);
  };

  const value: ProjectTimesheetContextType = useMemo(
    () => ({
      timesheetMonthStartDate,
      setTimesheetMonthStartDate,
      timesheetMonthDatesParsed,
      selectedTimesheetHoursCell,
      goToNextMonth,
      goToPreviousMonth,
      setSelectedTimesheetHoursCell,
      goToCurrentMonth,
    }),
    [
      timesheetMonthStartDate,
      setTimesheetMonthStartDate,
      timesheetMonthDatesParsed,
      selectedTimesheetHoursCell,
      goToNextMonth,
      goToPreviousMonth,
      setSelectedTimesheetHoursCell,
      goToCurrentMonth,
    ],
  );

  return <ProjectTimesheetContext.Provider value={value}>{children}</ProjectTimesheetContext.Provider>;
};
