import { Grid } from 'lux/components';

import { DashboardCard } from 'ui/dashboardCard/DashboardCard';

import { ProjectsList } from './projectsList/ProjectsList';
import * as styles from './ProjectsGrid.styles';
import { ProjectsListTopbar } from './projectsListTopbar/ProjectsListTopbar';
import { ProjectsFilters } from './projectsFilters/ProjectsFilters';

export const ProjectsGrid = () => (
  <Grid container gutter={0} sx={styles.projectsGridWrapper} overflow="hidden" flex={1}>
    <Grid overflow="hidden" display="flex" height="100%" flexDirection="column" item xs={3}>
      <DashboardCard
        sx={{
          wrapper: styles.cardWrapper,
        }}
      >
        <ProjectsFilters />
      </DashboardCard>
    </Grid>
    <Grid item xs={9} display="flex" flexDirection="column" height="100%" overflow="hidden">
      <DashboardCard
        sx={{
          wrapper: styles.cardWrapper,
        }}
      >
        <ProjectsListTopbar />
        <ProjectsList />
      </DashboardCard>
    </Grid>
  </Grid>
);
