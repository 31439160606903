import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useInfinitePeople } from 'hooks/useInfinitePeople/useInfinitePeople';
import { EmployeeCard } from 'app/employee/card/EmployeeCard';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll/useInfiniteScroll';

import { PeopleListSkeleton } from './PeopleList.skeleton';

export const PeopleList = () => {
  const { formatMessage } = useLocale();
  const { filters } = usePeopleFilters();

  const { ref, queryMethods } = useInfiniteScroll(useInfinitePeople(filters));
  const { data, isLoading } = queryMethods;

  const people = data?.pages.flatMap((page) => page.people);

  if (isLoading) {
    return <PeopleListSkeleton />;
  }

  return (
    <>
      {people?.length ? (
        people.map((person, index) => (
          <EmployeeCard
            key={person.employeeId}
            ref={index === people.length - 1 ? ref : null}
            employee={person}
            availabilityStatus={person.availabilityStatus}
          />
        ))
      ) : (
        <EmptyState
          illustration="searchPeople"
          title={formatMessage({ id: AppMessages['people.peopleList.empty.title'] })}
          message={formatMessage({ id: AppMessages['people.peopleList.empty.message'] })}
        />
      )}
    </>
  );
};
