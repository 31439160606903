import { useState } from 'react';

import { Button } from 'lux/components';
import { SendIcon } from 'lux/icons';

import { NeedCardModal } from 'app/need/card/modal/NeedCardModal';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { RequestNeed } from 'app/need/request/RequestNeed';
import { NeedsList } from 'shared/needs/needsList/NeedsList';
import { canRequestNeed } from 'utils/projectUtils';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';

import { TabNeedsPanelProps } from './TabNeeds.types';

export const TabNeedsPanel = ({ projectId }: TabNeedsPanelProps) => {
  const { formatMessage } = useLocale();
  const { isOpen: isNeedCardModalOpen, setOpen: setNeedCardModalOpen, setClose: setNeedCardModalClose } = useDialog();
  const { isOpen: isRequestModalOpen, setOpen: setRequestModalOpen, setClose: setRequestModalClose } = useDialog();
  const { projectDetails, allowProjectEdition } = useProjectDetails();

  const [activeNeedId, setActiveNeedId] = useState<string | null>(null);

  const requestNeedBtn = (
    <Button
      disabled={!canRequestNeed(allowProjectEdition, projectDetails.endDate)}
      variant="contained"
      size="medium"
      onClick={setRequestModalOpen}
      startIcon={<SendIcon />}
    >
      {formatMessage({ id: AppMessages['projectDetails.header.button.requestNeed'] })}
    </Button>
  );

  const handleNeedCardClick = (needId: string) => {
    setActiveNeedId(needId);
    setNeedCardModalOpen();
  };

  const handleNeedCardClose = () => {
    setActiveNeedId(null);
    setNeedCardModalClose();
  };

  return (
    <>
      <NeedsList projectId={projectId} onNeedClick={handleNeedCardClick} requestNeedBtn={requestNeedBtn} />
      {activeNeedId && (
        <NeedCardModal
          projectId={projectId}
          needId={activeNeedId}
          open={isNeedCardModalOpen}
          onClose={handleNeedCardClose}
        />
      )}
      <RequestNeed projectId={projectId} open={isRequestModalOpen} onClose={setRequestModalClose} />
    </>
  );
};
