import { Dialog } from '@mui/material';

import { CloseProjectModalProps } from './CloseProjectModal.types';
import { CloseProjectContent } from './closeProjectContent/CloseProjectContent';

export const CloseProjectModal = ({
  open,
  onClose,
  projectId,
  projectStartDate,
  projectEndDate,
  projectName,
}: CloseProjectModalProps) => (
  <Dialog fullWidth open={open} onClose={onClose}>
    <CloseProjectContent
      onClose={onClose}
      projectId={projectId}
      projectStartDate={projectStartDate}
      projectEndDate={projectEndDate}
      projectName={projectName}
    />
  </Dialog>
);
