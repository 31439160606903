import { GetInvoicesParams } from 'api/actions/getInvoices/getInvoices.types';

export const projectsKeys = {
  singleProject: (projectId: string) => [`/projects/${projectId}`] as const,
  singleProjectNeeds: (projectId: string) => [`/projects/${projectId}/needs`] as const,
  projectsList: ['/projects'] as const,
  projectBillingCycle: (projectId: string, fromDate?: string) =>
    [`/projects/${projectId}/billing-cycle`, fromDate || null] as const,
  projectLastGeneratedBillingCycle: (projectId: string) =>
    [`/projects/${projectId}/last-generated-billing-cycle`] as const,
  projectBillingSummary: (projectId: string, fromDate?: string, groupBy?: string) =>
    [`/projects/${projectId}/billing-summary`, fromDate || null, groupBy || null] as const,
  extremeConfirmedInvoice: (projectId: string, includeEarliest = false) =>
    [`/invoices/${projectId}/last-confirmed-invoice`, { includeEarliest }] as const,
};

export const clientsKeys = {
  clients: ['/clients'] as const,
  industriesList: ['/clients/industries'] as const,
  allClients: ['/clients/all'] as const,
  clientDetails: (clientId?: string) => [`/clients/${clientId}`] as const,
  clientInvoices: (clientId?: string) => [`/invoices/${clientId}`] as const,
};

export const peopleKeys = {
  singlePerson: (personId: string) => [`/people/${personId}`] as const,
  singlePersonProposals: (personId: string) => [`/people/${personId}/proposals`] as const,
  singlePersonAssignments: (personId: string) => [`/people/${personId}/assignments`] as const,
  singlePersonAbsences: (personId: string, fromDate?: string, toDate?: string) =>
    [`/people/${personId}/absences`, fromDate || null, toDate || null] as const,
  peopleList: ['/people'] as const,
  peopleAvailability: ['/people/availability'] as const,
};

export const dictionariesKeys = {
  skillsList: ['/dictionaries/people/skills'] as const,
  tagsList: ['/dictionaries/projects/tags'] as const,
  specializationsList: ['/dictionaries/people/specializations'] as const,
  projectRolesList: ['/dictionaries/projects/project-roles'] as const,
};

export const needsKeys = {
  singleNeed: (needId: string) => [`/needs/${needId}`] as const,
  needsList: ['/needs'] as const,
};
export const proposalsKeys = {
  proposalsList: (needId: string) => [`/needs/proposals/${needId}`] as const,
};

export const assignmentsKeys = {
  assignmentsList: ['/assignments'] as const,
};

export const rejectionsKeys = {
  rejectionsList: ['/rejections'] as const,
};

export const currentUserKey = [`/auth/me`] as const;

export const apiVersionKey = [`/version`] as const;

export const getInvoicesQueryKey = (params?: GetInvoicesParams) =>
  [...assignmentsKeys.assignmentsList, params] as const;
