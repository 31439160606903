import { Divider } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { SearchbarDebounced } from 'ui/searchbarDebounced/SearchbarDebounced';
import { useClientsFilters } from '../../../hooks/useClientsFilters/useClientsFilters';
import { ClientsActiveFilters } from '../../../shared/clients/clientsFilters/clientsActiveFilters/ClientsActiveFilters';

import * as styles from './ClientsListTopbar.styles';

export const ClientsListTopbar = () => {
  const { formatMessage } = useLocale();

  const { filters, setFilterValue } = useClientsFilters();

  const handleSearch = (value: string) => {
    setFilterValue('search', value);
  };

  const handleClear = () => {
    setFilterValue('search', '');
  };

  return (
    <>
      <SearchbarDebounced
        label={formatMessage({ id: AppMessages['clients.searchbar.label'] })}
        name="search"
        value={filters.search || ''}
        onChange={handleSearch}
        isClearable={!!filters.search}
        onClear={handleClear}
      />
      <Divider sx={styles.divider} />
      <ClientsActiveFilters />
    </>
  );
};
