import { Button, Tooltip } from 'lux/components';
import { useQueryClient } from '@tanstack/react-query';

import { useConfirmInvoice } from 'hooks/useConfirmInvoice/useConfirmInvoice';
import { useInvoiceCorrection } from 'hooks/useInvoiceCorrection/useInvoiceCorrection';
import { Translation } from 'ui/translation/Translation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { ApproveBillingCycleWarningModal } from '../additionalValuesModals/approveBillingCycleWarningModal/ApproveBillingCycleWarningModal';
import { getAssignments } from '../discrepancyHoursWarning/warningMessage/WarningMessage';
import { InvoiceStatusEnum } from 'api/types/BillingSummary.types';
import { isFutureDate, parseDate } from 'utils/dateUtils';
import { canEditInvoice } from 'utils/canEditInvoice';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProject } from 'hooks/useProject/useProject';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { isCurrentUserPmInProject } from 'utils/isCurrentUserPmInProject';
import { getInvoicesQueryKey, projectsKeys } from 'utils/queryKeys';

import { BillingSummaryActionsProps } from './BillingSummaryActions.types';

export const BillingSummaryActions = ({ invoice, billingCycle }: BillingSummaryActionsProps) => {
  const queryClient = useQueryClient();
  const { projectDetails } = useProjectDetails();
  const { isConfirmingInvoice, confirmInvoiceAsPm, isOpenWarningModal, setIsOpenWarningModal, forceConfirm } =
    useConfirmInvoice(invoice.id);
  const { isLoading: requestingInvoiceCorrection, mutate: requestInvoiceCorrection } = useInvoiceCorrection(
    invoice.id,
    projectDetails.client.id,
  );
  const { billingSummaryAssignments, confirmInvoiceError, invalidateBillingSummaryCache } =
    useProjectBillingSummaryData();
  const { data: user } = useCurrentUser();
  const { data } = useProject(billingCycle.projectId);
  const { t } = useLocale();
  const isButtonDisabled = !canEditInvoice(user);
  const projectId = invoice.projectId;

  const isPastOrCurrentBillingCycle = !isFutureDate(parseDate(billingCycle.startDate));

  const isPermittedToClickConfirmInvoice = isCurrentUserPmInProject(user, data?.assignments) || canEditInvoice(user);

  const handleCorrectInvoice = () => {
    requestInvoiceCorrection(
      {},
      {
        onSuccess: () => {
          invalidateBillingSummaryCache();
          queryClient.invalidateQueries(getInvoicesQueryKey({ projectId }));
          queryClient.refetchQueries(projectsKeys.extremeConfirmedInvoice(projectId));
        },
      },
    );
  };

  const shouldShowCorrectButton =
    (confirmInvoiceError.errorType && invoice.status !== InvoiceStatusEnum.to_correct) ||
    invoice.status === InvoiceStatusEnum.approved_by_pm;

  if (invoice.status === InvoiceStatusEnum.created) {
    return (
      <>
        <ApproveBillingCycleWarningModal
          open={isOpenWarningModal}
          forceConfirm={forceConfirm}
          assignments={getAssignments(billingSummaryAssignments)}
          onClose={() => {
            setIsOpenWarningModal(false);
          }}
        />
        {isPastOrCurrentBillingCycle && (
          <Tooltip
            placement="top"
            arrow
            title={isPermittedToClickConfirmInvoice && t('projectDetails.invoices.confirm.tooltip.message')}
          >
            <span>
              <Button
                size="small"
                loadingPosition="center"
                loading={isConfirmingInvoice}
                onClick={confirmInvoiceAsPm}
                disabled={!isPermittedToClickConfirmInvoice}
              >
                <Translation id="projectDetails.invoice.actions.confirm" />
              </Button>
            </span>
          </Tooltip>
        )}
      </>
    );
  }

  if (shouldShowCorrectButton) {
    return (
      <Button
        size="small"
        variant="outlined"
        loadingPosition="center"
        loading={requestingInvoiceCorrection}
        onClick={handleCorrectInvoice}
        disabled={isButtonDisabled}
      >
        <Translation id="projectDetails.invoice.actions.toCorrect" />
      </Button>
    );
  }

  if (invoice.status === InvoiceStatusEnum.to_correct) {
    return (
      <>
        <ApproveBillingCycleWarningModal
          forceConfirm={forceConfirm}
          open={isOpenWarningModal}
          assignments={getAssignments(billingSummaryAssignments)}
          onClose={() => {
            setIsOpenWarningModal(false);
          }}
        />
        <Button size="small" loadingPosition="center" loading={isConfirmingInvoice} onClick={confirmInvoiceAsPm}>
          <Translation id="projectDetails.invoice.actions.confirmCorrected" />
        </Button>
      </>
    );
  }

  return null;
};
