import { useState } from 'react';

import { Breakpoint, Dialog } from '@mui/material';

import { RequestNeedProps } from '../Need.types';

import { RequestNeedContent } from './requestNeedContent/RequestNeedContent';

export const RequestNeed = ({ projectId, open, onClose }: RequestNeedProps) => {
  const [dialogMaxWidth, setDialogMaxWidth] = useState<Breakpoint>('sm');

  const handleChangeSize = (size: Breakpoint) => {
    setDialogMaxWidth(size);
  };

  return (
    <Dialog open={open} maxWidth={dialogMaxWidth} fullWidth onClose={onClose}>
      <RequestNeedContent projectId={projectId} onClose={onClose} onChangeWidth={handleChangeSize} />
    </Dialog>
  );
};
