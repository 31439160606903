import { Grid } from 'lux/components';

import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { ClientsList } from '../clientsList/ClientsList';
import { ClientsListTopbar } from '../clientsListTopbar/ClientsListTopbar';
import { ClientsFilters } from '../clientsFilters/ClientsFilters';

import * as styles from './ClientsGrid.styles';

export const ClientsGrid = () => (
  <Grid container sx={styles.clientsGridWrapper} overflow="hidden" gutter={0}>
    <Grid overflow="hidden" display="flex" height="100%" flexDirection="column" item xs={3}>
      <DashboardCard
        sx={{
          wrapper: styles.filtersDashboardCardWrapper,
        }}
      >
        <ClientsFilters />
      </DashboardCard>
    </Grid>

    <Grid item xs={9} display="flex" flexDirection="column">
      <DashboardCard sx={{ wrapper: styles.listDashboardCardWrapper }}>
        <ClientsListTopbar />
        <ClientsList />
      </DashboardCard>
    </Grid>
  </Grid>
);
