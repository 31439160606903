import { Styles } from 'styles/theme';
import { isProduction } from 'utils/isProduction';

export const DRAWER_WIDTH = 57;
export const DRAWER_OPENED_WIDTH = 193;

export const drawer = (expanded: boolean): Styles => ({
  width: expanded ? DRAWER_OPENED_WIDTH : DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'absolute',
});

export const drawerPaper =
  (expanded: boolean): Styles =>
  (theme) => ({
    width: expanded ? DRAWER_OPENED_WIDTH : DRAWER_WIDTH,
    paddingX: 1,
    paddingTop: theme.topBarHeight + (!isProduction ? 9 : 2),
    paddingBottom: 4,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2,
    borderRight: '1px solid',
    borderRightColor: theme.palette.border.default,
    backgroundColor: theme.palette.surface.paper.default,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: expanded ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
  });
