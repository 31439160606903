import { Styles } from 'styles/theme';

export const menu: Styles = {
  marginLeft: -1,

  '.MuiMenu-paper': {
    minWidth: '245px',
    borderRadius: 2,
  },
};

export const menuItemWithCaption: Styles = {
  display: 'flex',
  flexDirection: 'column',
};
