import { Box } from '@mui/material';
import { Alert, Emoji, Typography } from 'lux/components';
import { InfoFilledIcon } from 'lux/icons';

import { AppMessages } from 'i18n/messages';
import { TimesheetTable } from 'app/project/projectTimesheet/timesheetTable/TimesheetTable';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { Translation } from 'ui/translation/Translation';
import { ProjectNavigationBar } from 'ui/projectNavigationBar/ProjectNavigationBar';
import { DiscrepancyHoursWarning } from '../projectBillingSummary/discrepancyHoursWarning/DiscrepancyHoursWarning';
import { WarningMessage } from '../projectBillingSummary/discrepancyHoursWarning/warningMessage/WarningMessage';
import { ProjectDetailsRoute } from 'routing/AppRoute.enum';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { formatDate, isDateAfter, isDateBefore, isDateInSameMonth, parseDate } from 'utils/dateUtils';
import { useBillingCycle } from 'hooks/useBillingCycle/useBillingCycle';
import { useLocale } from 'hooks/useLocale/useLocale';
import { LastUpdate } from 'app/project/lastUpdate/LastUpdate';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';
import { isStartOrEndDateAfterJanuary } from 'context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController.utils';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import { TimesheetEmptyState } from './timesheetEmptyState/TimesheetEmptyState';
import * as styles from './ProjectTimesheet.styles';
import { ProjectTimesheetSkeleton } from './projectTimesheetSkeleton/ProjectTimesheetSkeleton';
import { ProjectTimesheetProps } from './ProjectTimesheet.types';

export const ProjectTimesheet = ({ isClientView, timesheetNumber, tsNumberToShowNav }: ProjectTimesheetProps) => {
  const {
    projectDetails: { id: projectId, stage, startDate, endDate },
  } = useProjectDetails();
  const { hasBillingCycleDiscrepancy } = useProjectDiscrepancy();
  const { formatMessage, t } = useLocale();
  const { timesheetMonthStartDate, goToPreviousMonth, goToNextMonth, goToCurrentMonth } = useProjectTimesheet();
  const {
    isLoadingTimesheetData,
    timesheetData: workStatements,
    timesheetAssignments,
    billingCycleError,
    snapshotCreateDate,
    showTimesheetSnapshot,
    showMismatchDataError,
  } = useProjectTimesheetData();

  const isSelectedMonthAfterEndDate = isDateAfter(timesheetMonthStartDate, parseDate(endDate));
  const isSelectedMonthBeforeStartDateAndNotCurrent =
    isDateBefore(timesheetMonthStartDate, parseDate(startDate)) &&
    !isDateInSameMonth(timesheetMonthStartDate, parseDate(startDate));

  const enableBillingCycleFetch =
    ![ProjectStage.lead, ProjectStage.archived].includes(stage) &&
    isStartOrEndDateAfterJanuary(startDate, endDate) &&
    !isSelectedMonthAfterEndDate &&
    !isSelectedMonthBeforeStartDateAndNotCurrent;

  const { data: billingCycle } = useBillingCycle(
    projectId,
    formatDate(timesheetMonthStartDate),
    enableBillingCycleFetch,
  );
  const navArrowDisabledCondition =
    [ProjectStage.lead, ProjectStage.archived].includes(stage) || isLoadingTimesheetData || !!billingCycleError;
  const displayLastUpdateCondition = billingCycle?.result?.username && billingCycle?.result?.updatedAt;
  const showTsHeading = Boolean(tsNumberToShowNav === timesheetNumber && workStatements.length) || !isClientView;
  const showTsNav = timesheetNumber === 0 || !isClientView;

  return (
    <CardWrapper shouldShow={isClientView}>
      {hasBillingCycleDiscrepancy(projectId, timesheetMonthStartDate) && (
        <DiscrepancyHoursWarning
          message={<WarningMessage assignments={timesheetAssignments} />}
          to={ProjectDetailsRoute.timesheet}
          modifiedText
        />
      )}
      {showMismatchDataError && (
        <Alert
          severity="error"
          icon={<Emoji type="money" />}
          title={t('projectDetails.billingSummary.mismatchError.title')}
          description={t('projectDetails.billingSummary.mismatchError.description')}
          sx={styles.moneyEmoji}
        />
      )}
      {showTsNav && (
        <>
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.timesheetTitle}>
              <Translation id="projectDetails.timesheet.title" />
            </Typography>
            {!isClientView && displayLastUpdateCondition && (
              <LastUpdate
                updatedAt={billingCycle?.result?.updatedAt ?? ''}
                username={billingCycle?.result?.username ?? ''}
              />
            )}
          </Box>
          <Box sx={styles.navigationBar}>
            <ProjectNavigationBar
              selectedMonthStartDate={timesheetMonthStartDate}
              onPreviousMonthClick={goToPreviousMonth}
              onNextMonthClick={goToNextMonth}
              onCurrentMonthClick={goToCurrentMonth}
              isNavNextArrowDisabled={navArrowDisabledCondition}
              isNavPrevArrowDisabled={navArrowDisabledCondition}
              isClientView={isClientView}
            />
          </Box>
        </>
      )}
      {showTimesheetSnapshot && (
        <Alert
          severity="info"
          title={formatMessage(
            { id: AppMessages['projectDetails.timesheet.snapshot.info.alert'] },
            {
              date: snapshotCreateDate,
            },
          )}
          sx={styles.infoAlert}
          icon={<InfoFilledIcon />}
        />
      )}
      {isLoadingTimesheetData && <ProjectTimesheetSkeleton />}
      {!isLoadingTimesheetData &&
        (workStatements.length ? (
          <TimesheetTable showTsHeading={showTsHeading} isClientView={isClientView} />
        ) : (
          !isClientView && <TimesheetEmptyState />
        ))}
    </CardWrapper>
  );
};
