import { ProjectStage } from 'api/types/ProjectStage.enum';
import { ProjectTeam } from 'api/types/ProjectTeam.enum';
import { ProjectType } from 'api/types/ProjectType.enum';
import { ProjectsFilters } from 'context/projectsFilters/projectsFiltersContext/ProjectsFiltersContext.types';
import { Translation } from 'i18n/messages';

export type FilterOption = {
  labelTranslation: Translation;
  value: string;
};

type ProjectsFiltersData = Record<
  keyof Omit<ProjectsFilters, 'search' | 'sort' | 'tag' | 'role' | 'clientName' | 'people'>,
  FilterOption[]
>;

export const filtersData: ProjectsFiltersData = {
  type: [
    {
      labelTranslation: 'projects.type.greenfield',
      value: ProjectType.greenfield,
    },
    {
      labelTranslation: 'projects.type.existingCode',
      value: ProjectType.existingCode,
    },
    {
      labelTranslation: 'projects.type.projectOutsourcing',
      value: ProjectType.projectOutsourcing,
    },
    {
      labelTranslation: 'projects.type.peopleOutsourcing',
      value: ProjectType.peopleOutsourcing,
    },
    {
      labelTranslation: 'projects.type.internal',
      value: ProjectType.internal,
    },
    {
      labelTranslation: 'projects.type.workshop',
      value: ProjectType.workshop,
    },
    {
      labelTranslation: 'projects.type.fixedPrice',
      value: ProjectType.fixedPrice,
    },
    {
      labelTranslation: 'projects.type.timeAndMaterial',
      value: ProjectType.timeAndMaterial,
    },
  ],
  stage: [
    {
      labelTranslation: 'projects.filters.stage.option.lead',
      value: ProjectStage.lead,
    },
    {
      labelTranslation: 'projects.filters.stage.option.ongoing',
      value: ProjectStage.ongoing,
    },
    {
      labelTranslation: 'projects.filters.stage.option.closing',
      value: ProjectStage.closing,
    },
    {
      labelTranslation: 'projects.filters.stage.option.closed',
      value: ProjectStage.closed,
    },
    {
      labelTranslation: 'projects.filters.stage.option.archived',
      value: ProjectStage.archived,
    },
  ],
  team: [
    {
      labelTranslation: 'projects.filters.team.option.complete',
      value: ProjectTeam.complete,
    },
    {
      labelTranslation: 'projects.filters.team.option.incomplete',
      value: ProjectTeam.incomplete,
    },
  ],
};
