import { Styles } from 'styles/theme';

export const workTimeTab: Styles = {
  backgroundColor: 'theme.palette.common.white',
  border: '1px solid',
  borderColor: 'border.default',
  borderRadius: 4,
  py: 2,
  mb: 3,
};

export const icon: Styles = {
  backgroundColor: 'surface.paper.default',
  borderRadius: 2,
  padding: 0.75,
  mx: 1,
  alignSelf: 'center',
  lineHeight: 0,
};
