import { Typography } from 'lux/components';

import { GetApiVersionResponse } from 'api/actions/getApiVersion/getApiVersion.types';
import { getSpaVersion } from 'utils/getSpaVersion';

import * as styles from './Version.styles';

export const Version = ({ apiVersion }: GetApiVersionResponse) => {
  const spaVersion = getSpaVersion();

  const footer = spaVersion === apiVersion ? spaVersion : `FE: ${spaVersion} / BE: ${apiVersion}`;

  return <Typography sx={styles.version}>{footer}</Typography>;
};
