import { Box } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectNeeds } from 'hooks/useProjectNeeds/useProjectNeeds';
import { AppMessages } from 'i18n/messages';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { AddButton } from 'app/project/teamDetails/addButton/AddButton';
import { useProjectNeedsFilters } from 'hooks/useProjectNeedsFilters/useProjectNeedsFilters';

import { NeedsListProps } from './NeedsList.types';
import { NeedsTable } from './needsTable/NeedsTable';
import { NeedsListSkeleton } from './NeedsList.skeleton';

export const NeedsList = ({ projectId, onNeedClick, requestNeedBtn }: NeedsListProps) => {
  const { formatMessage } = useLocale();

  const { projectDetails, allowProjectEdition } = useProjectDetails();
  const { id } = projectDetails;
  const { filters } = useProjectNeedsFilters();

  const { data: needs = [], isLoading } = useProjectNeeds({ projectId, ...filters });

  if (isLoading) {
    return <NeedsListSkeleton />;
  }

  return (
    <>
      {needs.length > 0 ? (
        <>
          <NeedsTable needsData={needs} onNeedClick={onNeedClick} />
          <Box marginTop={1}>{allowProjectEdition && <AddButton projectId={id} />}</Box>
        </>
      ) : (
        <EmptyState
          illustration="emptyState"
          title={formatMessage({ id: AppMessages['projectDetails.tab.needs.empty.title'] })}
          message={formatMessage({ id: AppMessages['projectDetails.tab.needs.empty.subtitle'] })}
          actionBtn={requestNeedBtn}
        />
      )}
    </>
  );
};
