import { useEffect } from 'react';

import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Autocomplete } from 'ui/form/autocomplete/Autocomplete';
import { Select } from 'ui/form/select/Select';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { Loader } from 'ui/loader/Loader';
import * as styles from 'ui/form/form.styles';
import { SkillSets } from 'app/need/skillSets/SkillSets';
import { seniorityList } from 'config/data/seniorityList';
import { fteList } from 'config/data/fteList';
import { useProject } from 'hooks/useProject/useProject';
import { useSpecializations } from 'hooks/useSpecializations/useSpecializations';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { formatDate, isDateAfter, parseDate, subtractDaysFromDate } from 'utils/dateUtils';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { typeList } from 'app/need/data/type';

export const NeedForm = ({
  loading,
  projectId,
  replacedAssignmentId,
  variant = 'default',
}: {
  loading: boolean;
  projectId: string;
  replacedAssignmentId?: string;
  variant?: 'default' | 'replacement' | 'edit';
}) => {
  const { t } = useLocale();
  const { watch, control, trigger } = useFormContext();
  const { data: specializations, isLoading: isSpecializationsLoading } = useSpecializations();
  const { data, isLoading } = useProject(projectId);

  const [startDate, endDate, seniorityEnabled, fte, type] = watch([
    'startDate',
    'endDate',
    'seniorityEnabled',
    'fte',
    'type',
  ]);

  useEffect(() => {
    if (startDate) {
      trigger('startDate');
    }
  }, []);

  if (isLoading) {
    return <Loader fullHeight={false} />;
  }

  if (!data) {
    return null;
  }

  const getMaxDate = (endDate: string | undefined) => {
    if (endDate) {
      return subtractDaysFromDate(new Date(endDate), 1);
    }

    if (parseDate(data.endDate)) {
      return subtractDaysFromDate(parseDate(data.endDate), 1);
    }
  };

  const isNeedEndAfterProjectEnd = data.endDate && isDateAfter(endDate, new Date(data.endDate));
  const isNeedTypeDisabled = variant === 'edit' && !!replacedAssignmentId;

  return (
    <form>
      <Box sx={styles.formElementWrapperFixed}>
        {variant !== 'replacement' && (
          <>
            <Box sx={styles.formElementBox}>
              <Select
                fullWidth
                name="type"
                control={control}
                label={t('need.form.type')}
                options={typeList}
                isDisabled={loading || isNeedTypeDisabled}
                data-cy="need-form_type"
              />
            </Box>
            {type === 'replacement' && (
              <Box sx={styles.formElementBox}>
                <FormTextField
                  type="number"
                  min={1}
                  max={999}
                  fullWidth
                  name="replacedDays"
                  control={control}
                  isDisabled={loading || isNeedTypeDisabled}
                  label={t('need.form.replacedDays')}
                  data-cy="need-form_replaced-days"
                />
              </Box>
            )}
          </>
        )}

        <Autocomplete
          name="role"
          control={control}
          label={t('need.form.role')}
          options={mapToAutocompleteOptions(specializations || [])}
          loading={isSpecializationsLoading}
          freeSolo={true}
          sx={styles.formElementBox}
          disabled={loading}
          data-cy="need-form_role"
        />
        <Stack sx={styles.formElementBox} alignItems="center" columnGap={1} direction="row">
          <Checkbox control={control} name="seniorityEnabled" size="medium" data-cy="need-form_seniority-enabled" />
          <Select
            data-cy="need-form_seniority"
            name="seniority"
            control={control}
            label={t('need.form.seniority')}
            options={seniorityList()}
            fullWidth
            isDisabled={!seniorityEnabled || loading}
          />
        </Stack>
        <Box sx={styles.formElementBox}>
          <Select
            fullWidth
            name="fte"
            control={control}
            label={t('need.form.fte')}
            options={fteList()}
            isDisabled={loading}
            data-cy="need-form_fte"
          />
        </Box>
        {fte === 'other' && (
          <Box sx={styles.formElementBox}>
            <FormTextField
              type="number"
              min={1}
              max={40}
              fullWidth
              name="fteCustom"
              control={control}
              isDisabled={loading}
              label={t('need.form.fteCustom')}
              data-cy="need-form_custom-fte"
            />
          </Box>
        )}
        <Stack
          sx={styles.datePickerWrapper}
          columnGap={3}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <DatePicker
            minDate={parseDate(data.startDate)}
            maxDate={getMaxDate(data?.endDate)}
            name="startDate"
            control={control}
            label={t('need.form.availableFrom')}
            isDisabled={loading}
            disableUntilDate={parseDate(data.startDate)}
            data-cy="need-form_start-date"
          />
          <DatePicker
            minDate={startDate ? new Date(startDate) : parseDate(data.startDate)}
            name="endDate"
            control={control}
            label={t('need.form.availableTo.label')}
            isDisabled={loading}
            disableUntilDate={startDate ? new Date(startDate) : parseDate(data.startDate)}
            data-cy="need-form_end-date"
          />
        </Stack>
        {isNeedEndAfterProjectEnd && (
          <InfoBox
            variant="warning"
            title={t('need.form.warning.needEndAfterProjectEnd', {
              previousEndDate: data.endDate,
              endDate: formatDate(endDate),
            })}
          />
        )}
      </Box>

      <SkillSets loading={loading} />

      <Box sx={styles.formElementWrapperFixed}>
        <FormTextField
          multiline
          rows={2}
          fullWidth
          sx={styles.formElementBox}
          name="additionalInformation"
          control={control}
          label={t('need.form.additionalInfo')}
          isDisabled={loading}
          data-cy="need-form_additional-info"
        />
      </Box>
    </form>
  );
};
