import { Styles } from 'styles/theme';

export const menu: Styles = {
  marginTop: 1,

  '.MuiMenu-paper': {
    minWidth: '245px',
    borderRadius: 2,
  },
};

export const optionWithError: Styles = {
  '&.MuiMenuItem-root:not(.Mui-disabled)': {
    color: 'error.main',

    '& .MuiListItemIcon-root path': {
      fill: (theme) => theme.palette.error.main,
    },
  },
};

export const menuItemWithCaption: Styles = {
  display: 'flex',
  flexDirection: 'column',
};
