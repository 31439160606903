import { useEffect, useLayoutEffect } from 'react';

import { Box } from '@mui/material';

import { WorkStatement } from 'api/types/BillingSummary.types';
import { ProjectBillingSummary } from 'app/project/projectBillingSummary/ProjectBillingSummary';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { isDateInSameMonth } from 'utils/dateUtils';

import { ClientBillingSummaryAccordionItemProps } from './ClientBillingSummaryAccordionItem.types';

export const ClientBillingSummaryAccordionItem = (props: ClientBillingSummaryAccordionItemProps) => {
  const {
    bINumber,
    monthForBI,
    setMonthForBI,
    groupByAllBI,
    setLoading,
    setGroupByAllBI,
    setTotalAmountOfBI,
    totalAmountOfBI,
    onCheckboxChange,
    invoiceDataAction,
    invoiceData,
    issueInvoiceMode,
  } = props;
  const { projectDetails } = useProjectDetails();

  const { setSelectedBIDate, selectedBIDate, workStatements, isLoadingBillingSummaryData } =
    useProjectBillingSummaryData();

  const updateTotalAmount = (workStatement: WorkStatement) => {
    const existingProject = totalAmountOfBI.find(({ id }) => id === workStatement.id);

    if (existingProject) {
      existingProject.amount !== workStatement.total &&
        setTotalAmountOfBI((prevVal) =>
          prevVal.map((project) =>
            project.id === existingProject.id ? { ...project, amount: workStatement.total } : project,
          ),
        );
      return;
    }
    setTotalAmountOfBI((prevVal) => [
      ...prevVal,
      { projectId: projectDetails.id, id: workStatement.id, amount: workStatement.total },
    ]);
  };

  const isTheFirstBI = bINumber === 0;

  useLayoutEffect(() => {
    const areDateNotEqual = !isDateInSameMonth(selectedBIDate, monthForBI);

    if (isTheFirstBI && areDateNotEqual) {
      setMonthForBI(selectedBIDate);
    }

    if (!isTheFirstBI && areDateNotEqual) {
      setSelectedBIDate(monthForBI);
    }
  }, [monthForBI, selectedBIDate]);

  useEffect(() => {
    if (workStatements.length > 0) {
      const workStatement = workStatements[0];
      workStatement?.total && updateTotalAmount(workStatement);
    }
  }, [workStatements, selectedBIDate, monthForBI, totalAmountOfBI]);

  useEffect(() => {
    setLoading(bINumber, !!isLoadingBillingSummaryData);
  }, [isLoadingBillingSummaryData]);

  return (
    <Box>
      <ProjectBillingSummary
        isClientView={true}
        bINumber={bINumber}
        groupByAllBI={groupByAllBI}
        setGroupByAllBI={setGroupByAllBI}
        onCheckboxChange={onCheckboxChange}
        invoiceData={invoiceData}
        invoiceDataAction={invoiceDataAction}
        issueInvoiceMode={issueInvoiceMode}
        setMonthForBI={setMonthForBI}
      />
    </Box>
  );
};
