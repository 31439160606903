import { AdditionalValuesModal } from '../additionalValuesModal/AdditionalValuesModal';

import { AddDiscountModalProps } from './AddDiscountModal.types';
import { AddDiscountContent } from './addDiscountContent/AddDiscountContent';

export const AddDiscountModal = ({
  open,
  onClose,
  workStatementId,
  billingCycleId,
  invoiceId,
}: AddDiscountModalProps) => (
  <AdditionalValuesModal open={open} onClose={onClose}>
    <AddDiscountContent
      onClose={onClose}
      workStatementId={workStatementId}
      billingCycleId={billingCycleId}
      invoiceId={invoiceId}
    />
  </AdditionalValuesModal>
);
