import { Dialog } from '@mui/material';

import { AddWorkStatementContent } from './addWorkStatementContent/AddWorkStatementContent';
import { AddWorkStatementModalProps } from './AddWorkStatementModal.types';

export const AddWorkStatementModal = ({
  open,
  onClose,
  projectId,
  projectStartDate,
  projectEndDate,
  projectCurrency,
  useProjectStartDate,
}: AddWorkStatementModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <AddWorkStatementContent
      onClose={onClose}
      useProjectStartDate={useProjectStartDate}
      projectId={projectId}
      projectStartDate={projectStartDate}
      projectEndDate={projectEndDate}
      projectCurrency={projectCurrency}
    />
  </Dialog>
);
