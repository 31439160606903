import { Box, Button, Stack } from '@mui/material';
import { Badge, Typography } from 'lux/components';
import { ChevronDownIcon, ChevronUpIcon } from 'lux/icons';

import type { ProposalStats } from 'api/types/Need.types';
import { chooseMainSkillsByWeight } from 'app/need/utils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useNeedModal } from 'hooks/useNeedModal/useNeedModal';
import { AppMessages } from 'i18n/messages';
import { HubspotBadge } from 'ui/hubspotBadge/HubspotBadge';
import { Translation } from 'ui/translation/Translation';
import { NeedSteps } from '../body/needSteps/NeedSteps';

import * as styles from './NeedCardModalHeader.styles';
import type { NeedCardModalHeaderProps } from './NeedCardModalHeader.types';

const getCurrentStep = (stats: ProposalStats, forcedStep?: number) =>
  forcedStep || (stats.approved ? 2 : stats.proposed ? 1 : 0);

export const NeedCardModalHeader = (props: NeedCardModalHeaderProps) => {
  const { role, projectName = '', startDate, isHubSpotNeed, showAllSkills, skillSet } = props;
  const { isConfirmAssignOpen, projectDetails, needData, detailsVisible, canToggleDetails, toggleDetails } =
    useNeedModal();
  const { formatMessage } = useLocale();
  const skills = showAllSkills ? skillSet : chooseMainSkillsByWeight(skillSet);

  if (!projectDetails || !needData) {
    return null;
  }

  const currentStep = getCurrentStep(needData.proposalStats, isConfirmAssignOpen ? 3 : undefined);

  return (
    <>
      <Box sx={role ? styles.needCardModalHeaderWrapper : null}>
        {role && (
          <Box sx={styles.needCardModalHeaderMainInfo}>
            <Typography variant="caption">{role}</Typography>
            {projectName && (
              <Typography color="text.secondary" variant="caption">
                {projectName}
              </Typography>
            )}
          </Box>
        )}

        <Box sx={styles.needCardModalHeaderOtherInfo}>
          <Stack sx={styles.needCardModalFooter} gap={2}>
            <Typography sx={styles.needCardModalHeaderDate} variant="caption">
              <Translation id="card.startDate" values={{ date: startDate }} />
            </Typography>

            {skills.length > 0 && (
              <Box sx={styles.skillsContainer}>
                {skills.map((item, index) => (
                  <Badge isStatic key={`${item.name}-${index}`} color="gray" badgeContent={item.name} />
                ))}
                {!showAllSkills && skillSet.length > skills.length && (
                  <Badge isStatic color="gray" badgeContent={`+${skillSet.length - skills.length}`} />
                )}
              </Box>
            )}
          </Stack>

          {canToggleDetails && (
            <Button
              sx={styles.toggleDetailsButton}
              variant="text"
              size="large"
              startIcon={detailsVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
              disableRipple
              onClick={toggleDetails}
              data-cy="card-need_body-btn"
            >
              <Translation id="card.need.body.details" />
            </Button>
          )}

          <Stack direction="row" gap={1}>
            {isHubSpotNeed && (
              <HubspotBadge
                title={formatMessage({ id: AppMessages['card.need.badge.hubspot.title'] })}
                tooltipDescription={formatMessage({ id: AppMessages['card.need.badge.hubspot.tooltip'] })}
              />
            )}
          </Stack>
        </Box>
      </Box>

      {currentStep > 0 && <NeedSteps currentStep={currentStep} />}
    </>
  );
};
