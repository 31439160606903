import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'lux/components';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useNeedModal } from 'hooks/useNeedModal/useNeedModal';
import { useRemoveProposal } from 'hooks/useRemoveProposal/useRemoveProposal';
import { AppMessages } from 'i18n/messages';
import { needsKeys, projectsKeys, proposalsKeys, rejectionsKeys } from 'utils/queryKeys';

import * as styles from './ConfirmRemoveModal.styles';
import type { ConfirmRemoveModalProps } from './ConfirmRemoveModal.types';

export const ConfirmRemoveModal = ({ open }: ConfirmRemoveModalProps) => {
  const { projectDetails, needData, selectedProposal, closeConfirmRemove } = useNeedModal();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const { mutate: removeProposal, isLoading: isRemoveProposalLoading } = useRemoveProposal();

  if (!projectDetails || !needData || !selectedProposal) {
    return null;
  }

  const employee = selectedProposal.person;
  const handleRemove = () => {
    const proposalId = selectedProposal.id;
    const projectId = projectDetails.id;
    const needId = needData.id;

    removeProposal(
      {
        projectId,
        proposalId,
        needId,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['snackbar.removeProposal.success'] },
              {
                personName: `${employee.firstName} ${employee.lastName}`,
              },
            ),
          );
          queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
          queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
          queryClient.invalidateQueries(needsKeys.singleNeed(needId));
          queryClient.invalidateQueries(needsKeys.needsList);
          queryClient.invalidateQueries(rejectionsKeys.rejectionsList);
          queryClient.invalidateQueries(proposalsKeys.proposalsList(needId));
          closeConfirmRemove();
        },
        onError: () => {
          enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.removeProposal.error'] }), {
            variant: 'error',
          });
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={closeConfirmRemove} fullWidth maxWidth="sm" sx={styles.customDialog}>
      <DialogTitle>
        {formatMessage(
          { id: AppMessages['card.need.proposed.removeProposalModal.title'] },
          {
            personName: `${employee.firstName} ${employee.lastName}`,
          },
        )}
      </DialogTitle>
      <DialogContent>
        {formatMessage({ id: AppMessages['card.need.proposed.removeProposalModal.description'] })}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeConfirmRemove}>
          {formatMessage({ id: AppMessages['button.cancel'] })}
        </Button>
        <Button loading={isRemoveProposalLoading} variant="contained" onClick={handleRemove}>
          {formatMessage({ id: AppMessages['button.remove'] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
