import { useEffect, useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';
import { Button } from 'lux/components';
import { FormProvider } from 'react-hook-form';

import { AssignPersonArguments, AssignPersonError } from 'api/actions/assignPerson/assignPersonActions.types';
import { Person } from 'api/actions/getPerson/getPerson.types';
import { Seniority } from 'api/types/Seniority.enum';
import { Fte } from 'config/data/fteList';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProject } from 'hooks/useProject/useProject';
import { AppMessages } from 'i18n/messages';
import { AssignOrProposeAnywayModal } from 'shared/assignOrProposeAnywayModal/AssignOrProposeAnywayModal';
import { AssignPersonButton } from 'shared/assignPersonButton/AssignPersonButton';
import { PeopleActionsModal } from 'shared/peopleActionsModal/PeopleActionsModal';
import { convertRateToInteger } from 'utils/convertRate';
import { formatDate } from 'utils/dateUtils';
import { isPlaceholder } from 'utils/isPlaceholder';

import { AssignmentDetails } from './assignmentDetails/AssignmentDetails';
import { assignWithoutNeedSchema } from './AssignWithoutNeed.schema';
import {
  AssignWithoutNeedFormValues,
  AssignWithoutNeedModalProps,
  AssignWithoutNeedModalStep,
} from './AssignWithoutNeedModal.types';
import { defaultValues } from './defaultValues';

export const AssignWithoutNeedModal = ({
  projectId,
  isOpen,
  onClose,
  onAssign,
  isAssignmentInProgress = false,
  defaultFilters,
}: AssignWithoutNeedModalProps) => {
  const { formatMessage } = useLocale();
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const currentStep = selectedPerson ? AssignWithoutNeedModalStep.assignForm : null;
  const [warnings, setWarnings] = useState<AssignPersonError | null>(null);
  const [isVisible, setIsVisible] = useState(true);

  const {
    isOpen: isForceAssignModalOpen,
    setOpen: setForceAssignModalOpen,
    setClose: setForceAssignModalClose,
  } = useDialog();

  const { data: projectData } = useProject(projectId);

  const form = useIntlForm<AssignWithoutNeedFormValues>({
    mode: 'onSubmit',
    defaultValues,
    resolver: joiResolver(assignWithoutNeedSchema(projectData?.startDate, projectData?.endDate)),
  });

  const rateCard = projectData?.currentRateCard;
  const [selectedSeniority, selectedRole] = form.watch(['seniority', 'role']);
  const selectedRoleRates =
    rateCard &&
    Object.entries(rateCard.items).find(([roleName]) => roleName.toLowerCase() === selectedRole?.toLowerCase())?.[1];
  const isRateDefined = !selectedRole || !!selectedRoleRates?.find(({ seniority }) => seniority === selectedSeniority);
  const isSenna = rateCard?.items[selectedRole]?.[0].seniority === Seniority.withoutLevel;

  const onSubmit = (values: AssignWithoutNeedFormValues, { force } = { force: false }) => {
    if (!selectedPerson || !projectData) {
      return;
    }

    const { customFte, fte, customRateEnabled, customRate, temporary, startDate, endDate, ...rest } = values;

    const payload: AssignPersonArguments = {
      assignment: {
        personId: selectedPerson.employeeId,
        fte: fte === Fte.other ? customFte : fte,
        startDate: startDate && formatDate(startDate),
        endDate: endDate && formatDate(endDate),
        ...rest,
      },
      ...(customRateEnabled && { proposedRateInProject: convertRateToInteger(customRate) }),
      force,
      temporary,
    };

    onAssign(
      payload,
      {
        onSuccess: () => {
          handleAssignAnywayModalClose();
          onClose();
        },
        onError: (error) => {
          if (error.response?.status === 409) {
            setWarnings(error.response.data);
            setForceAssignModalOpen();
            setIsVisible(false);
          }
        },
      },
      {
        employeeName: `${selectedPerson.firstName} ${selectedPerson.lastName}`,
        projectName: projectData.name,
      },
    );
  };

  const handlePersonSelect = (person: Person) => {
    setSelectedPerson(person);
  };

  const handleBackToList = () => {
    setSelectedPerson(null);
  };

  const handleAssignAnywayModalClose = () => {
    setWarnings(null);
    setForceAssignModalClose();
    setIsVisible(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedPerson(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedPerson) {
      form.reset({
        ...defaultValues,
        seniority: selectedPerson.seniority || Seniority.withoutLevel,
      });
    }
  }, [selectedPerson]);

  useEffect(() => {
    if (isSenna) {
      form.setValue('seniority', Seniority.withoutLevel);
    }
  }, [isSenna]);

  if (!projectData || !rateCard) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <PeopleActionsModal
        isOpen={isOpen}
        onClose={onClose}
        personAction={(person) => (
          <AssignPersonButton
            tooltipMaxWidth={278}
            tooltipPlacement="right"
            person={person}
            customDisable={isPlaceholder(person)}
            customMessage={formatMessage({ id: AppMessages['card.need.approved.placeholderError'] })}
            onClick={handlePersonSelect}
          />
        )}
        currentStepName={currentStep}
        peopleListTitle={formatMessage({ id: AppMessages['assignWithoutNeed.list.title'] })}
        isVisible={isVisible}
        defaultPeopleFilters={defaultFilters}
        steps={[
          {
            name: AssignWithoutNeedModalStep.assignForm,
            title:
              selectedPerson &&
              formatMessage(
                { id: AppMessages['assignWithoutNeed.formModal.title'] },
                {
                  personName: `${selectedPerson.firstName} ${selectedPerson.lastName}`,
                  projectName: projectData.name,
                },
              ),
            content: selectedPerson && (
              <>
                <Stack>
                  <AssignmentDetails
                    project={projectData}
                    person={selectedPerson}
                    rateCard={rateCard}
                    isSenna={isSenna}
                    selectedRoleRates={selectedRoleRates}
                    isRateDefined={isRateDefined}
                  />
                </Stack>
                {warnings && (
                  <AssignOrProposeAnywayModal
                    mode="assign"
                    open={isForceAssignModalOpen}
                    onClose={handleAssignAnywayModalClose}
                    loading={isAssignmentInProgress}
                    startDate={form.getValues().startDate?.toISOString()}
                    employeeId={selectedPerson.employeeId}
                    projectId={projectData.id}
                    onSubmit={form.handleSubmit((values) => onSubmit(values, { force: true }))}
                    warnings={warnings}
                  />
                )}
              </>
            ),
            actions: (
              <>
                <Button variant="outlinedGray" onClick={handleBackToList}>
                  {formatMessage({ id: AppMessages['assignWithoutNeed.button.back'] })}
                </Button>
                <Button
                  loading={isAssignmentInProgress}
                  variant="contained"
                  onClick={form.handleSubmit((values) => onSubmit(values))}
                >
                  {formatMessage({ id: AppMessages['assignWithoutNeed.button.assign'] })}
                </Button>
              </>
            ),
            modalMaxWidth: 'sm',
          },
        ]}
      />
    </FormProvider>
  );
};
