import { getExtremeConfirmedInvoice } from 'api/actions/getExtremeConfirmedInvoice/getExtremeConfirmedInvoice';
import { GetExtremeConfirmedInvoiceResponse } from 'api/actions/getExtremeConfirmedInvoice/getExtremeConfirmedInvoice.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { projectsKeys } from 'utils/queryKeys';

export const useExtremeConfirmedInvoice = (projectId: string, includeEarliest = false) => {
  const { isAuthenticated } = useAuth();

  const { data, ...rest } = useQuery<{}, GetExtremeConfirmedInvoiceResponse>(
    projectsKeys.extremeConfirmedInvoice(projectId, includeEarliest),
    getExtremeConfirmedInvoice(projectId, includeEarliest),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );
  return { latestInvoice: data?.latestInvoice, earliestInvoice: data?.earliestInvoice, ...rest };
};
