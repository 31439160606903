import { useEffect } from 'react';

import { useOutletContext, useSearchParams } from 'react-router-dom';

import { ProjectsFiltersContext } from '../projectsFiltersContext/ProjectsFiltersContext';
import { ProjectsSortingField } from '../projectsFiltersContext/ProjectsFiltersContext.types';
import { useFilters } from 'hooks/useFilters/useFilters';
import { generateSortFilter } from 'utils/filters/filters';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { filterEmptyQueryParams, stringifyQueryParams } from 'api/utils/queryString';
import { useOnRedirectHandler } from 'hooks/useOnRedirectHandler/useOnRedirectHandler';
import type { ProjectsFilters } from '../projectsFiltersContext/ProjectsFiltersContext.types';

import type { ProjectsFiltersContextControllerProps } from './ProjectsFiltersContextController.types';

export const projectsSortingOptions = generateSortFilter(ProjectsSortingField);

const defaultFilters: ProjectsFilters = {
  search: '',
  sort: projectsSortingOptions.nameASC,
  type: [],
  stage: [ProjectStage.lead, ProjectStage.ongoing, ProjectStage.closing],
  team: '',
  role: [],
  tag: [],
  clientName: [],
  people: [],
};

const emptyFilters: ProjectsFilters = { ...defaultFilters, stage: [] };

export const ProjectsFiltersContextController = ({
  children,
  withParams,
  storageKey,
  storageType,
}: ProjectsFiltersContextControllerProps) => {
  const [, setParamsFilters] = useSearchParams();
  const outletProps = useOutletContext<{ clientName?: string } | undefined>();
  const contextValues = useFilters<ProjectsFilters>(defaultFilters, { withParams, storageKey, storageType });

  useEffect(() => {
    if (!outletProps?.clientName) {
      return;
    }
    contextValues.setFilterValue('clientName', [outletProps?.clientName]);
  }, [outletProps?.clientName]);

  useEffect(() => {
    if (withParams) {
      setParamsFilters(stringifyQueryParams(filterEmptyQueryParams(contextValues.filters, emptyFilters)), {
        replace: true,
      });
    }
  }, []);

  useOnRedirectHandler(() => {
    contextValues.setStorageFiltersState({
      search: undefined,
    });
  });

  return <ProjectsFiltersContext.Provider value={contextValues}>{children}</ProjectsFiltersContext.Provider>;
};
