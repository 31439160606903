import { Styles } from 'styles/theme';

export const detailsTab: Styles = {
  backgroundColor: 'surface.paper.default',
  border: '1px solid',
  borderColor: 'border.default',
  borderRadius: 4,
  padding: 3,
};

export const detailsHeadingWrapper: Styles = {
  marginBottom: 0,
};

export const detailsHeading: Styles = {
  fontSize: 16,
};
