import { EmojiType } from 'lux/components/atoms/emoji/Emoji.types';

export const getDigitEmoji = (n: number): EmojiType => {
  switch (n) {
    case 1:
      return 'digitOne';
    case 2:
      return 'digitTwo';
    case 3:
      return 'digitThree';
    case 4:
      return 'digitFour';
    case 5:
      return 'digitFive';
    case 6:
      return 'digitSix';
    case 7:
      return 'digitSeven';
    default:
      return 'digitOne';
  }
};

export const doDateRangesOverlap = (
  absenceStart: Date,
  absenceEnd: Date,
  assignmentStart: Date,
  assignmentEnd: Date | null,
): boolean => {
  // If no assignment end date, check if absence starts after or on assignment start
  if (!assignmentEnd) {
    return absenceEnd >= assignmentStart;
  }

  // Normal overlap check
  return absenceStart <= assignmentEnd && assignmentStart <= absenceEnd;
};
