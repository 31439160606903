import { cloneElement } from 'react';

import { DataDisplayProps, LinkComponentType } from 'lux/components/molecules/dataDisplay/DataDisplay.types';
import { Grid, Typography } from 'lux/components';
import clsx from 'clsx';
import { Stack } from '@mui/material';

import { headerStyles, valueStyles } from './AssignmentDetailsDataDisplay.styles';

export const AssignmentDetailsDataDisplay = <TLinkComponent extends LinkComponentType>({
  header,
  headerLinkComponent,
  children,
  valueVariant = 'normal',
}: DataDisplayProps<TLinkComponent>) => {
  const value =
    typeof children === 'string' ? (
      <Typography
        variant={'caption'}
        sx={{ ...valueStyles, ...(valueVariant === 'large' && { fontSize: '0.875rem' }) }}
      >
        {children}
      </Typography>
    ) : (
      children
    );

  if (headerLinkComponent) {
    return (
      <Stack spacing={0.5}>
        <Typography fontWeight={600} variant={'caption'} sx={headerStyles}>
          {cloneElement(headerLinkComponent, {
            children: header,
            className: clsx(headerLinkComponent.props.className, 'headerLink'),
          })}
        </Typography>
        {value}
      </Stack>
    );
  }

  return (
    <Grid container item>
      <Grid item xs={6}>
        <Typography variant={'caption'} sx={headerStyles}>
          {header}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {value || children}
      </Grid>
    </Grid>
  );
};
