import { Dialog } from '@mui/material';

import { UpdateRateCardContent } from './updateRateCardContent/UpdateRateCardContent';
import * as styles from './UpdateRateCardModal.styles';
import { UpdateRateCardModalProps } from './UpdateRateCardModal.types';

export const UpdateRateCardModal = ({
  open,
  onClose,
  updatedRateCard,
  minDate,
  title,
  currentRateCard,
  isInternal,
}: UpdateRateCardModalProps) => (
  <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose} sx={styles.customDialog}>
    <UpdateRateCardContent
      onClose={onClose}
      updatedRateCard={updatedRateCard}
      minDate={minDate}
      title={title}
      currentRateCard={currentRateCard}
      isInternal={isInternal}
    />
  </Dialog>
);
