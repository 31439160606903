import { useState } from 'react';

import { Box } from '@mui/material';
import { Button, Tooltip } from 'lux/components';
import { useSnackbar } from 'notistack';

import type { AssignPersonError } from 'api/actions/assignPerson/assignPersonActions.types';
import type { Person } from 'api/actions/getPerson/getPerson.types';
import { createDepartmentLabel } from 'config/data/departments';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { useAssignPerson } from 'hooks/useAssignPerson/useAssignPerson';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProject } from 'hooks/useProject/useProject';
import { AppMessages } from 'i18n/messages';
import { AssignOrProposeAnywayModal } from 'shared/assignOrProposeAnywayModal/AssignOrProposeAnywayModal';
import { AssignPersonButton } from 'shared/assignPersonButton/AssignPersonButton';
import { PeopleActionsModal } from 'shared/peopleActionsModal/PeopleActionsModal';
import { Loader } from 'ui/loader/Loader';
import { Translation } from 'ui/translation/Translation';
import { convertRateToInteger } from 'utils/convertRate';
import { isPlaceholder } from 'utils/isPlaceholder';
import { getApiError } from '../../../../../../../api/utils/getApiError';
import { ComparisonTable } from '../comparisonTable/ComparisonTable';

import type { PeopleAssignImmediatelyModalProps } from './PeopleAssignImmediatelyModal.types';
import { PeopleAssignImmediatelyModalStep } from './PeopleAssignImmediatelyModal.types';

export const PeopleAssignImmediatelyModal = ({
  needData,
  rateCard,
  projectId,
  isOpen,
  onClose,
  onSuccess: onAssignSuccess,
}: PeopleAssignImmediatelyModalProps) => {
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const [currentStep, setCurrentStep] = useState<PeopleAssignImmediatelyModalStep | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [effectiveRate, setEffectiveRate] = useState<number | null>(null);
  const [warnings, setWarnings] = useState<AssignPersonError | null>(null);

  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  const { checkAvailableRoleForUser } = useAssignmentInfo();

  const {
    isOpen: isForceAssignModalOpen,
    setOpen: setForceAssignModalOpen,
    setClose: setForceAssignModalClose,
  } = useDialog();

  const { data: projectData, isLoading: isProjectDataLoading } = useProject(projectId);
  const { mutate: assignPersonImmediately, isLoading: isAssignPersonImmediatelyLoading } = useAssignPerson(projectId);

  const handleSubmitButtonClick = ({ force } = { force: false }) => {
    if (!selectedPerson || !projectData) {
      return;
    }

    assignPersonImmediately(
      {
        proposal: {
          needId: needData.id,
          personId: selectedPerson.employeeId.toString(),
        },
        ...(effectiveRate !== null && { proposedRateInProject: convertRateToInteger(effectiveRate) }),
        force,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['snackbar.assignPerson.success'] },
              {
                personName: `${selectedPerson.firstName} ${selectedPerson.lastName}`,
                projectName: projectData.name,
                role: createDepartmentLabel(needData.role),
              },
            ),
          );
          onAssignSuccess();
        },

        onError: (error) => {
          if (error.response?.status === 409) {
            setWarnings(error.response.data);
            setForceAssignModalOpen();
            setIsVisible(false);
          } else {
            enqueueSnackbar(
              formatMessage({
                id: getApiError(error, {
                  fallback: formatMessage({ id: AppMessages['snackbar.assignPersonToProject.error'] }),
                }),
              }),
              { variant: 'error' },
            );
          }
        },
      },
    );
  };

  const handlePersonSelect = (person: Person) => {
    setSelectedPerson(person);
    setCurrentStep(PeopleAssignImmediatelyModalStep.comparisonTable);
  };

  const handleBackToList = () => {
    setSelectedPerson(null);
    setCurrentStep(null);
    setEffectiveRate(null);
  };

  const isRoleOrRateMissing = !rateCard || !needData || !rateCard.items[needData.role];

  if (isProjectDataLoading || !projectData) {
    return <Loader fullHeight={false} />;
  }

  return (
    <PeopleActionsModal
      isOpen={isOpen}
      onClose={onClose}
      currentStepName={currentStep}
      peopleListTitle={formatMessage({ id: AppMessages['card.need.peopleList.assign.title'] })}
      personAction={(person) => {
        const availableRole = checkAvailableRoleForUser(person.employeeId);
        const isNotTheSameRole = !!(availableRole && availableRole !== needData.role);

        return (
          <AssignPersonButton
            tooltipMaxWidth={278}
            tooltipPlacement="right"
            person={person}
            customDisable={isPlaceholder(person) || isNotTheSameRole}
            customMessage={
              isNotTheSameRole
                ? formatMessage({ id: AppMessages['card.need.approved.mismatchWithRole'] })
                : formatMessage({ id: AppMessages['card.need.approved.placeholderError'] })
            }
            onClick={handlePersonSelect}
          />
        );
      }}
      isVisible={isVisible}
      steps={[
        {
          name: PeopleAssignImmediatelyModalStep.comparisonTable,
          title: formatMessage({ id: AppMessages['card.need.assignPerson.summary.title'] }),
          content: selectedPerson && (
            <>
              <ComparisonTable
                projectId={projectId}
                needData={needData}
                personId={String(selectedPerson.employeeId)}
                effectiveRate={effectiveRate}
                onEffectiveRateChange={setEffectiveRate}
              />
              {warnings && (
                <AssignOrProposeAnywayModal
                  mode="assign"
                  open={isForceAssignModalOpen}
                  onClose={() => {
                    setForceAssignModalClose();
                    setWarnings(null);
                    setIsVisible(true);
                  }}
                  startDate={needData.startDate}
                  projectId={projectId}
                  loading={isAssignPersonImmediatelyLoading}
                  warnings={warnings}
                  employeeId={selectedPerson.employeeId}
                  onSubmit={() => handleSubmitButtonClick({ force: true })}
                />
              )}
            </>
          ),
          actions: (
            <>
              <Button variant="outlinedGray" onClick={handleBackToList}>
                {formatMessage({ id: AppMessages['card.need.peopleActions.button.back'] })}
              </Button>
              <Tooltip
                arrow
                placement="top"
                title={isRoleOrRateMissing && <Translation id={'card.need.missing.rate'} />}
              >
                <Box ml={2}>
                  <Button
                    loading={isAssignPersonImmediatelyLoading}
                    variant="contained"
                    disabled={isRoleOrRateMissing}
                    onClick={() => handleSubmitButtonClick()}
                  >
                    {formatMessage({ id: AppMessages['button.assignTeamMember'] })}
                  </Button>
                </Box>
              </Tooltip>
            </>
          ),
        },
      ]}
    />
  );
};
