import { useState, MouseEvent } from 'react';

import { CloseRoundedIcon, MoreIcon, RemoveUserIcon, StarIcon, CalendarIcon, SendIcon, EraseIcon } from 'lux/icons';
import { MenuList, Tooltip, Typography } from 'lux/components';
import { enqueueSnackbar } from 'notistack';
import { Box } from '@mui/material';

import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useEditAssignment } from 'hooks/useEditAssignment/useEditAssignment';
import { getAssignmentRemovalDisabledReason } from 'utils/projectUtils';
import { Loader } from 'ui/loader/Loader';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';

import { RemoveImmediatelyModal } from './removeImmediatelyModal/RemoveImmediatelyModal';
import { PlanRemovalModal } from './planRemovalModal/PlanRemovalModal';
import { EditAssignmentModal } from './editAssignmentModal/EditAssignmentModal';
import { ExtendEndDateModal } from './extendEndDateModal/ExtendEndDateModal';
import { CancelPlannedRemovalModal } from './cancelPlannedRemovalModal/CancelPlannedRemovalModal';
import { ActionsButtonsProps } from './AssignmentMenuButton.types';
import * as styles from './AssignmentMenuButton.styles';
import { AbsenceReplacementModal } from './absenceReplacementModal/AbsenceReplacementModal';

export const AssignmentMenuButton = ({ assignmentData, projectAssignments }: ActionsButtonsProps) => {
  const { formatMessage } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mutate, isLoading: isEditAssignmentLoading } = useEditAssignment(
    assignmentData.project.id,
    assignmentData.id,
  );

  const open = Boolean(anchorEl);
  const {
    isOpen: isPlanRemovalModalOpen,
    setOpen: setPlanRemovalModalOpen,
    setClose: setPlanRemovalModalClose,
  } = useDialog();
  const {
    isOpen: isRemoveImmediatelyModalOpen,
    setOpen: setRemoveImmediatelyModalOpen,
    setClose: setRemoveImmediatelyModalClose,
  } = useDialog();
  const {
    isOpen: isEditAssignmentModalOpen,
    setOpen: setEditAssignmentModalOpen,
    setClose: setEditAssignmentModalClose,
  } = useDialog();
  const {
    isOpen: isCancelPlannedRemovalModalOpen,
    setOpen: setCancelPlannedRemovalModalOpen,
    setClose: setCancelPlannedRemovalModalClose,
  } = useDialog();
  const {
    isOpen: isExtendEndDateModalOpen,
    setOpen: setExtendEndDateModalOpen,
    setClose: setExtendEndDateModalClose,
  } = useDialog();

  const {
    isOpen: isAbsenceReplacementModalOpen,
    setOpen: setAbsenceReplacementModalOpen,
    setClose: setAbsenceReplacementModalClose,
  } = useDialog();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMarkLeader = () => {
    mutate(
      { isLeader: !assignmentData.isLeader },
      {
        onSuccess: () => {
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['assignment.editAssignment.snackbar.success'] },
              {
                personName: `${assignmentData.person.firstName} ${assignmentData.person.lastName}`,
              },
            ),
          );
        },
      },
    );
  };

  const removalDisabledReason = getAssignmentRemovalDisabledReason(assignmentData.project, assignmentData);
  const disableRemoval = Boolean(removalDisabledReason);
  const disableMarkLeader = projectAssignments.some(
    (assignment) =>
      assignment.isLeader && assignment.status !== AssignmentStatus.left && assignment.id !== assignmentData.id,
  );

  if (isEditAssignmentLoading) {
    return <Loader fullHeight={false} />;
  }

  const handleEditAssignmentClick = () => {
    handleMenuClose();
    setEditAssignmentModalOpen();
  };

  const handleTeamMemberExtensionClick = () => {
    handleMenuClose();
    setExtendEndDateModalOpen();
  };

  const handleMarkLeaderClick = () => {
    handleMenuClose();
    handleMarkLeader();
  };

  const handlePlanRemovalClick = () => {
    handleMenuClose();
    setPlanRemovalModalOpen();
  };

  const handleCancelPlanedRemovalClick = () => {
    handleMenuClose();
    setCancelPlannedRemovalModalOpen();
  };

  const handleRemoveImmediatelyClick = () => {
    setRemoveImmediatelyModalOpen();
    handleMenuClose();
  };

  const handleSetAbsenceReplacementClick = () => {
    handleMenuClose();
    setAbsenceReplacementModalOpen();
  };

  return (
    <>
      <SquareIconButton color="primary" onClick={handleMenuOpen} size="medium">
        <MoreIcon />
      </SquareIconButton>

      <MenuList
        sx={styles.menu}
        id={`assignment-menu-${assignmentData.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuList.MenuItem onClick={handleEditAssignmentClick} icon={<EraseIcon />}>
          <Box sx={styles.menuItemWithCaption}>
            {formatMessage({ id: AppMessages['assignment.menu.editAssignment'] })}
            <Typography variant="caption">
              {formatMessage({ id: AppMessages['assignment.menu.editAssignment.caption'] })}
            </Typography>
          </Box>
        </MenuList.MenuItem>
        <MenuList.MenuItem onClick={handleTeamMemberExtensionClick} icon={<CalendarIcon />}>
          {formatMessage({ id: AppMessages['assignment.menu.teamMember.extension'] })}
        </MenuList.MenuItem>
        {!disableMarkLeader && (
          <MenuList.MenuItem onClick={handleMarkLeaderClick} icon={<StarIcon />}>
            {formatMessage({
              id: AppMessages[!assignmentData.isLeader ? 'assignment.menu.markLeader' : 'assignment.menu.unmarkLeader'],
            })}
          </MenuList.MenuItem>
        )}
        {!assignmentData.endDate && (
          <MenuList.MenuItem onClick={handlePlanRemovalClick} icon={<RemoveUserIcon />}>
            {formatMessage({ id: AppMessages['assignment.menu.planRemoval'] })}
          </MenuList.MenuItem>
        )}
        {assignmentData.endDate && (
          <MenuList.MenuItem onClick={handleCancelPlanedRemovalClick} icon={<CloseRoundedIcon />}>
            {formatMessage({ id: AppMessages['assignment.menu.cancelPlannedRemoval'] })}
          </MenuList.MenuItem>
        )}
        <Tooltip arrow placement="left" title={removalDisabledReason}>
          <div>
            <MenuList.MenuItem
              sx={styles.optionWithError}
              onClick={handleRemoveImmediatelyClick}
              disabled={disableRemoval}
              icon={<RemoveUserIcon />}
            >
              {formatMessage({ id: AppMessages['assignment.menu.removeImmediately'] })}
            </MenuList.MenuItem>
          </div>
        </Tooltip>
        <MenuList.MenuItem onClick={handleSetAbsenceReplacementClick} icon={<SendIcon />}>
          {formatMessage({ id: AppMessages['assignment.menu.absenceReplacement'] })}
        </MenuList.MenuItem>
      </MenuList>
      <PlanRemovalModal
        assignmentData={assignmentData}
        open={isPlanRemovalModalOpen}
        onClose={setPlanRemovalModalClose}
      />
      <RemoveImmediatelyModal
        assignmentData={assignmentData}
        open={isRemoveImmediatelyModalOpen}
        onClose={setRemoveImmediatelyModalClose}
      />
      <EditAssignmentModal
        assignmentData={assignmentData}
        open={isEditAssignmentModalOpen}
        onClose={setEditAssignmentModalClose}
      />
      <CancelPlannedRemovalModal
        assignmentData={assignmentData}
        open={isCancelPlannedRemovalModalOpen}
        onClose={setCancelPlannedRemovalModalClose}
      />
      <ExtendEndDateModal
        assignmentData={assignmentData}
        open={isExtendEndDateModalOpen}
        onClose={setExtendEndDateModalClose}
      />
      <AbsenceReplacementModal
        projectId={assignmentData.project.id}
        assignmentData={assignmentData}
        open={isAbsenceReplacementModalOpen}
        onClose={setAbsenceReplacementModalClose}
      />
    </>
  );
};
