import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useParams } from 'react-router';
import isEqual from 'lodash/isEqual';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { mapRateCard } from 'app/createProject/rateCard/RateCard.mappers';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { useEditRates } from 'hooks/useEditRates/useEditRates';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { formatDate } from 'utils/dateUtils';
import { useProjectDetails } from '../../../../../../hooks/useProjectDetails/useProjectDetails';
import { ProjectStage } from '../../../../../../api/types/ProjectStage.enum';

import { RateCard } from './rateCard/RateCard';
import { mapRateCardFormItems } from './UpdateRateCardModal.mappers';
import { updateRateCardModalSchema } from './UpdateRateCardContent.schema';
import { RateCardItemsFormType, UpdateRateCardContentProps } from './UpdateRateCardContent.types';
import * as styles from './UpdateRateCardContent.styles';

export const UpdateRateCardContent = ({
  onClose,
  updatedRateCard,
  minDate,
  title,
  currentRateCard,
  isInternal,
}: UpdateRateCardContentProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const {
    projectDetails: { stage },
  } = useProjectDetails();

  if (!projectId) throw new Error('Project id is missing in the url params');

  const { mutateAsync, isLoading } = useEditRates(projectId);

  const initialRateCards = mapRateCardFormItems(updatedRateCard.items);

  const defaultValues: RateCardItemsFormType = {
    appliesFrom: stage === ProjectStage.lead ? new Date() : '',
    rateCards: initialRateCards,
  };

  const form = useIntlForm<RateCardItemsFormType>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(updateRateCardModalSchema(minDate, isInternal)),
  });

  const onSubmit = async (addRateCardItems: RateCardItemsFormType) => {
    const payload = {
      appliesFrom: formatDate(addRateCardItems.appliesFrom),
      rateCardItems: mapRateCard(addRateCardItems.rateCards),
    };

    await mutateAsync(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const rateCards = form.watch('rateCards');

  const mappedCurrentRateCard = mapRateCardFormItems(currentRateCard.items);
  const areRateCardsEqualToCurrent = isEqual(rateCards, mappedCurrentRateCard);

  return (
    <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
      <DialogInnerWrapper
        headerSx={styles.updateRateCardHeader}
        title={title}
        loading={isLoading}
        onClose={onClose}
        onSubmit={form.handleSubmit(onSubmit)}
        isFormValid={form.formState.isValid && !areRateCardsEqualToCurrent}
      >
        <FormProvider {...form}>
          <RateCard rateCard={updatedRateCard} minDate={minDate} />
        </FormProvider>
      </DialogInnerWrapper>
    </DialogClickAwayListenerWrapper>
  );
};
