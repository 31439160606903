import { Dialog } from '@mui/material';

import { EditNeedProps } from '../Need.types';

import { EditNeedContent } from './editNeedContent/EditNeedContent';

export const EditNeed = ({ projectId, need, open, onClose }: EditNeedProps) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
    <EditNeedContent projectId={projectId} need={need} onClose={onClose} />
  </Dialog>
);
