import { useState } from 'react';

import { Drawer } from '@mui/material';
import { Button } from 'lux/components';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DollarIcon,
  InfoIcon,
  InvoicesIcon,
  NoteIcon,
  OpenFolderIcon,
} from 'lux/icons';

import { useClient } from 'hooks/useClient/useClient';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ClientDetailsRoute } from 'routing/AppRoute.enum';

import * as styles from './Sidebar.styles';
import { SidebarProps } from './Sidebar.types';
import { SidebarButton } from './sidebarButton/SidebarButton';
import { SidebarHeader } from './sidebarHeader/SidebarHeader';

export const Sidebar = ({ pinned, togglePin }: SidebarProps) => {
  const { formatMessage } = useLocale();
  const { clientDetails } = useClient();
  const [open, setOpen] = useState(pinned || false);

  return (
    <Drawer
      open
      onMouseEnter={() => !pinned && setOpen(true)}
      onMouseLeave={() => !pinned && setOpen(false)}
      sx={styles.drawer(open)}
      variant="permanent"
      PaperProps={{
        sx: styles.drawerPaper(open),
      }}
    >
      <SidebarHeader title={clientDetails?.client?.name || ''} iconOnly={!open} />
      <Button variant="text" size="small" iconOnly onClick={togglePin}>
        {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Button>
      <SidebarButton
        to={ClientDetailsRoute.overview}
        Icon={InfoIcon}
        label={formatMessage({ id: AppMessages['sidebar.client.details'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.projects}
        Icon={OpenFolderIcon}
        label={formatMessage({ id: AppMessages['sidebar.client.projects'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.timesheets}
        Icon={NoteIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.timesheet'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.summary}
        Icon={DollarIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.billing'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.invoices}
        Icon={InvoicesIcon}
        label={formatMessage({ id: AppMessages['sidebar.client.invoices'] })}
        iconOnly={!open}
      />
    </Drawer>
  );
};
