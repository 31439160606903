import { useEffect, useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { Typography, InlineEdit } from 'lux/components';
import { FieldValues } from 'react-hook-form';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditMode } from 'hooks/useEditMode/useEditMode';
import { dashIfEmpty } from 'utils/stringUtils';
import { isDateAfter, isDateBefore, isValidDate } from 'utils/dateUtils';

import { DatePickerEditProps } from './DatePickerEdit.types';
import { datePickerEditSchema } from './DatePickerEdit.schema';

export const DatePickerEdit = <T extends FieldValues>(props: DatePickerEditProps<T>) => {
  const {
    name,
    label,
    value,
    minDate,
    maxDate,
    defaultValues,
    onSubmit,
    loading,
    disableUntilDate,
    required,
    disabled,
    defaultCalendarMonth,
    minErrorDate,
    maxErrorDate,
    minErrorDateText,
    maxErrorDateText,
  } = props;
  const { formatMessage } = useLocale();
  const { isEditMode, setOpenEditMode, setCloseEditMode, onFormSubmit } = useEditMode(onSubmit, disabled);

  const { control, handleSubmit, reset, watch, setError } = useIntlForm({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(datePickerEditSchema(name, formatMessage({ id: AppMessages[label] }), required)),
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const date = watch(name);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (isValidDate(date) && minErrorDate && minErrorDateText && !isDateAfter(date, minErrorDate)) {
      timeout = setTimeout(() =>
        setError(name, { type: 'error', message: formatMessage({ id: AppMessages[minErrorDateText] }) }),
      );
      setSubmitDisabled(true);
    } else if (isValidDate(date) && maxErrorDate && maxErrorDateText && !isDateBefore(date, maxErrorDate)) {
      timeout = setTimeout(() =>
        setError(name, { type: 'error', message: formatMessage({ id: AppMessages[maxErrorDateText] }) }),
      );
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [date, minErrorDate, minErrorDateText, maxErrorDate, maxErrorDateText]);

  return (
    <InlineEdit
      header={formatMessage({ id: AppMessages[label] })}
      isActive={isEditMode}
      onDataDisplayClick={() => {
        reset(defaultValues);
        setOpenEditMode();
      }}
      onCancel={() => {
        reset(defaultValues);
        setCloseEditMode();
      }}
      onSubmit={handleSubmit(onFormSubmit)}
      loading={loading}
      disabled={disabled}
      submitDisabled={submitDisabled}
      input={
        <DatePicker
          name={name}
          control={control}
          label={formatMessage({ id: AppMessages[label] })}
          size="small"
          autoFocus
          minDate={minDate}
          maxDate={maxDate}
          fullWidth
          disableUntilDate={disableUntilDate}
          defaultCalendarMonth={defaultCalendarMonth}
        />
      }
    >
      <Typography variant="caption">{dashIfEmpty(value)}</Typography>
    </InlineEdit>
  );
};
