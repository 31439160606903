import { Emoji, Tooltip } from 'lux/components';
import { Box, Stack } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { formatDate, isDateBefore, isDateBetween, isFutureDate, parseDate } from 'utils/dateUtils';
import { AssignmentType } from 'api/types/AssignmentType.enum';

import { EmojiBadgesProps } from './EmojiBadges.types';
import * as styles from './EmojiBadges.styles';
import { doDateRangesOverlap, getDigitEmoji } from './EmojiBadges.utils';

export const EmojiBadges = ({
  employmentType,
  isLeader,
  proposalCount,
  employedTo,
  absences,
  assignment,
}: EmojiBadgesProps) => {
  const { formatMessage } = useLocale();

  const mappedAbsences = absences?.timePeriod?.map((el) => ({ ...el, from: parseDate(el.from), to: parseDate(el.to) }));

  const filteredAbsences = assignment
    ? mappedAbsences?.filter((absence) => {
        const assignmentStartDate = parseDate(assignment.startDate);
        const assignmentEndDate = assignment.extensionDate ?? assignment.endDate;
        const parsedEndDate = assignmentEndDate ? parseDate(assignmentEndDate) : null;
        return doDateRangesOverlap(absence.from, absence.to, assignmentStartDate, parsedEndDate);
      })
    : mappedAbsences;

  const replacedAssignment = assignment?.replacedAssignment
    ? `${assignment.replacedAssignment.person.firstName} ${assignment.replacedAssignment.person.lastName}`
    : undefined;
  const hasReplacementNeed = !!assignment?.replacementNeeds?.length;
  const hasReplacement = !!assignment?.replacements?.length;
  const replacements = assignment?.replacements
    ?.map(({ person: { firstName, lastName } }) => `${firstName} ${lastName}`)
    .join(', ');

  const currentLeave = filteredAbsences?.find((interval) =>
    isDateBetween(new Date(), { start: interval.from, end: interval.to }),
  );
  const isCurrentlyOnLeave = !!currentLeave;
  const holidayEndDate = isCurrentlyOnLeave ? formatDate(currentLeave.to) : '';

  const futureLeave = filteredAbsences?.reduce<typeof mappedAbsences[number] | undefined>((prev, curr) => {
    if (!prev) return curr;

    if (isFutureDate(curr.from) && isDateBefore(curr.from, prev.from) && curr.duration >= 3) {
      return curr;
    }

    return prev;
  }, undefined);

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={styles.emojiBadges}>
      {isCurrentlyOnLeave && (
        <Tooltip
          title={formatMessage({ id: AppMessages['emojiBadge.onHolidays'] }, { holidayEndDate })}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="holiday" />
          </Box>
        </Tooltip>
      )}
      {!isCurrentlyOnLeave && futureLeave && (
        <Tooltip
          title={formatMessage(
            { id: AppMessages['emojiBadge.futureHolidays'] },
            { dayCount: futureLeave.duration, startingDate: formatDate(futureLeave.from) },
          )}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="calendar" />
          </Box>
        </Tooltip>
      )}
      {employmentType === EmploymentType.LTSH && (
        <Tooltip title={formatMessage({ id: AppMessages['emojiBadge.subcontractor'] })} placement="top" arrow>
          <Box>
            <Emoji type="outsource" />
          </Box>
        </Tooltip>
      )}
      {isLeader && (
        <Tooltip title={formatMessage({ id: AppMessages['emojiBadge.leader'] })} placement="top" arrow>
          <Box>
            <Emoji type="star" />
          </Box>
        </Tooltip>
      )}
      {proposalCount > 0 && (
        <Tooltip
          title={formatMessage({ id: AppMessages['emojiBadge.assignedProjects'] }, { count: proposalCount })}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type={getDigitEmoji(proposalCount)} />
          </Box>
        </Tooltip>
      )}
      {assignment?.type === AssignmentType.replacement && (
        <Tooltip
          title={
            replacedAssignment
              ? formatMessage({ id: AppMessages['emojiBadge.replacementFor'] }, { replacedAssignment })
              : formatMessage({ id: AppMessages['emojiBadge.replacement'] })
          }
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="clock" />
          </Box>
        </Tooltip>
      )}
      {hasReplacement && (
        <Tooltip
          title={formatMessage({ id: AppMessages['emojiBadge.assignmentReplaced'] }, { replacements })}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="okHand" />
          </Box>
        </Tooltip>
      )}
      {hasReplacementNeed && (
        <Tooltip title={formatMessage({ id: AppMessages['emojiBadge.replacementNeedCreated'] })} placement="top" arrow>
          <Box>
            <Emoji type="eyes" />
          </Box>
        </Tooltip>
      )}
      {futureLeave && !hasReplacementNeed && !hasReplacement && assignment && (
        <Tooltip title={formatMessage({ id: AppMessages['emojiBadge.replacementRequired'] })} placement="top" arrow>
          <Box>
            <Emoji type="questionMark" />
          </Box>
        </Tooltip>
      )}
      {employedTo && (
        <Tooltip
          title={formatMessage({ id: AppMessages['emojiBadge.employedTo'] }, { employedTo })}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="printer" />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
