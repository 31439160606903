import { Dialog } from '@mui/material';

import { AddUncertainModalProps } from './AddUncertainModal.types';
import { AddUncertainContent } from './addUncertainContent/AddUncertainContent';

export const AddUncertainModal = ({ open, onClose }: AddUncertainModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <AddUncertainContent onClose={onClose} />
  </Dialog>
);
