import { Styles } from 'lux/theme';

export const headerStyles: Styles = {
  color: 'text.secondary',
  '.headerLink': {
    color: 'primary.main',
    textDecoration: 'none',
  },
};

export const valueStyles: Styles = {
  color: 'text.primary',
  fontWeight: 500,
};
