import { useState, MouseEvent } from 'react';

import { EditIcon, EraseIcon } from 'lux/icons';
import { MenuList, Typography } from 'lux/components';
import { Box } from '@mui/material';

import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useEditAssignment } from 'hooks/useEditAssignment/useEditAssignment';
import { Loader } from 'ui/loader/Loader';
import { ActionsButtonsProps } from 'app/project/teamDetails/tabAssigned/assignmentDetailsMenuButton/AssignmentDetailsMenuButton.types';
import * as styles from 'app/project/teamDetails/tabAssigned/assignmentDetailsMenuButton/AssignmentDetailsMenuButton.styles';
import { EditAssignmentModal } from '../assignmentMenuButton/editAssignmentModal/EditAssignmentModal';

export const AssignmentDetailsMenuButton = ({ assignmentData }: ActionsButtonsProps) => {
  const { formatMessage } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { isLoading: isEditAssignmentLoading } = useEditAssignment(assignmentData.project.id, assignmentData.id);

  const open = Boolean(anchorEl);
  const {
    isOpen: isEditAssignmentModalOpen,
    setOpen: setEditAssignmentModalOpen,
    setClose: setEditAssignmentModalClose,
  } = useDialog();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (isEditAssignmentLoading) {
    return <Loader fullHeight={false} />;
  }

  const handleEditAssignmentClick = () => {
    handleMenuClose();
    setEditAssignmentModalOpen();
  };

  return (
    <>
      <SquareIconButton color="primary" onClick={handleMenuOpen} size="medium">
        <EditIcon />
      </SquareIconButton>

      <MenuList
        sx={styles.menu}
        id={`assignment-menu-${assignmentData.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList.MenuItem onClick={handleEditAssignmentClick} icon={<EraseIcon />}>
          <Box sx={styles.menuItemWithCaption}>
            {formatMessage({ id: AppMessages['assignment.menu.editAssignment'] })}
            <Typography variant="caption">
              {formatMessage({ id: AppMessages['assignment.menu.editAssignment.caption'] })}
            </Typography>
          </Box>
        </MenuList.MenuItem>
      </MenuList>
      <EditAssignmentModal
        assignmentData={assignmentData}
        open={isEditAssignmentModalOpen}
        onClose={setEditAssignmentModalClose}
      />
    </>
  );
};
